import React, { useState, useEffect } from 'react';
import { 
  useParams, 
  useHistory,
  useLocation
} from 'react-router-dom';
import { 
  Typography, 
  Button, 
  Card, 
  Space, 
  Checkbox, 
  Tabs, 
  Modal, 
  Form, 
  Input, 
  Select,
  Tag,
  Divider,
  Progress,
  Radio,
  Row,
  Col,
  Tooltip,
  Empty,
  List,
  Rate,
  message,
  Breadcrumb
} from 'antd';
import { 
  ArrowLeftOutlined, 
  PlusOutlined, 
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  InfoCircleOutlined,
  UserOutlined,
  ShoppingOutlined,
  CloseOutlined,
  HomeOutlined,
  ShareAltOutlined
} from '@ant-design/icons';
import '../../assets/css/components/plans.css';
import ShareModal from '../components/modals/ShareModal';

// Add CSS for fade animations
const fadeStyles = document.createElement('style');
fadeStyles.textContent = `
  @keyframes fadeInAnimation {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;
document.head.appendChild(fadeStyles);

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

// Sample plan detail data for testing
const mockPlans = {
  '123e4567-e89b-12d3-a456-426614174000': {
    uuid: '123e4567-e89b-12d3-a456-426614174000',
    name: 'Get Ready for Baby',
    description: 'Prepare everything for the baby\'s arrival',
    modules: ['Shopping', 'Research', 'Appointments'],
    stats: {
      total_items: 5,
      completed_items: 2,
      completion_percentage: 40,
      sampling_items: 2,
      training_items: 1,
      building_items: 3
    },
    items_by_module: {
      'Shopping': [
        {
          uuid: 'item-uuid-1',
          name: 'Buy crib',
          description: 'Find a safe and comfortable crib',
          status: 'completed',
          priority: 'must_have',
          trial_suggested: true,
          shared_by: null
        },
        {
          uuid: 'item-uuid-2',
          name: 'Buy car seat',
          description: 'Research and purchase appropriate car seat',
          status: 'completed',
          priority: 'must_have',
          training_required: true,
          shared_by: null
        }
      ],
      'Research': [
        {
          uuid: 'item-uuid-3',
          name: 'Research pediatricians',
          description: 'Find a pediatrician for the baby',
          status: 'in_progress',
          priority: 'must_have',
          shared_by: {
            name: 'Emma Wilson',
            uuid: 'user-1'
          }
        }
      ],
      'Appointments': [
        {
          uuid: 'item-uuid-4',
          name: 'Schedule hospital tour',
          description: 'Book a maternity ward tour',
          status: 'not_started',
          priority: 'nice_to_have',
          latest_date: '2023-11-15',
          latest_date_reason: 'Should be done before third trimester',
          shared_by: null
        },
        {
          uuid: 'item-uuid-5',
          name: 'Pre-register at hospital',
          description: 'Complete pre-registration paperwork',
          status: 'not_started',
          priority: 'must_have',
          building_required: true,
          latest_date: '2023-12-01',
          latest_date_reason: 'Required before delivery',
          shared_by: null
        }
      ]
    }
  },
  '123e4567-e89b-12d3-a456-426614174001': {
    uuid: '123e4567-e89b-12d3-a456-426614174001',
    name: 'Build Nursery',
    description: 'Create a cozy and functional nursery for baby',
    modules: ['Research', 'Plan', 'Setup'],
    stats: {
      total_items: 3,
      completed_items: 1,
      completion_percentage: 33,
      sampling_items: 1,
      training_items: 0,
      building_items: 2
    },
    items_by_module: {
      'Research': [
        {
          uuid: 'item-uuid-6',
          name: 'Research nursery furniture',
          description: 'Find the best crib, changing table, and dresser options',
          status: 'completed',
          priority: 'must_have',
          shared_by: null
        }
      ],
      'Plan': [
        {
          uuid: 'item-uuid-7',
          name: 'Create nursery layout',
          description: 'Plan the arrangement of furniture in the nursery',
          status: 'not_started',
          priority: 'must_have',
          building_required: true,
          shared_by: null
        }
      ],
      'Setup': [
        {
          uuid: 'item-uuid-8',
          name: 'Assemble and arrange nursery furniture',
          description: 'Put together the crib, dresser, and other items',
          status: 'not_started',
          priority: 'must_have',
          building_required: true,
          shared_by: null
        }
      ]
    }
  }
};

// Sample suggested items
const suggestedItems = [
  {
    uuid: 'sugg-item-1',
    name: 'Install baby car seat',
    description: 'Get car seat professionally installed or learn how to do it correctly',
    module: 'Shopping',
    priority: 'must_have',
    training_required: true
  },
  {
    uuid: 'sugg-item-2',
    name: 'Pack hospital bag',
    description: 'Prepare all essentials for hospital stay',
    module: 'Appointments',
    priority: 'must_have',
    latest_date: '2023-12-01',
    latest_date_reason: 'Should be ready 2-4 weeks before due date'
  }
];

const PlanDetails = ({ listId: propListId, isInExploreModal, onBackToExplore }) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isAddItemModalVisible, setIsAddItemModalVisible] = useState(false);
  const [isAddItemCustomModalVisible, setIsAddItemCustomModalVisible] = useState(false);
  const [isViewDetailsModalVisible, setIsViewDetailsModalVisible] = useState(false);
  const [modalFadeState, setModalFadeState] = useState('visible'); // 'visible', 'fadeOut', 'fadeIn'
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterValue, setFilterValue] = useState('all');
  const [sortValue, setSortValue] = useState('module');
  const [showHidden, setShowHidden] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [showEmptyProductsState, setShowEmptyProductsState] = useState(true); // Default to showing "No suggested products" state until we have real data
  const [showEmptyRecommendationsState, setShowEmptyRecommendationsState] = useState(true); // Default to showing "No suggested brands/models" state
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [selectedItemForShare, setSelectedItemForShare] = useState(null);
  const [recommendedProducts] = useState([
    {
      id: 'prod-1',
      title: 'Baby Car Seat', 
      description: 'Safe and comfortable infant car seat',
      price: '$120-$200',
      category: 'Safety',
      image: require('../../assets/images/Categories_CarSeat.png'),
      rating: 4.7,
      trialSuggested: true,
      trainingRequired: true
    },
    {
      id: 'prod-2',
      title: 'Convertible Crib', 
      description: 'Grows with your baby from crib to toddler bed',
      price: '$200-$500',
      category: 'Nursery',
      image: require('../../assets/images/Categories_Crib.png'),
      rating: 4.5,
      trainingRequired: false,
      trialSuggested: false
    },
    {
      id: 'prod-3',
      title: 'Baby Monitor', 
      description: 'HD video and audio monitoring',
      price: '$80-$300',
      category: 'Safety',
      image: require('../../assets/images/Categories_Baby_Monitor.png'),
      rating: 4.6,
      trainingRequired: true,
      trialSuggested: false
    },
    {
      id: 'prod-4',
      title: 'Stroller', 
      description: 'Lightweight and easy to fold travel stroller',
      price: '$120-$350',
      category: 'Travel',
      image: require('../../assets/images/Categories_Strollers.png'),
      rating: 4.4,
      trainingRequired: false,
      trialSuggested: true
    },
    {
      id: 'prod-5',
      title: 'Changing Table', 
      description: 'Sturdy changing table with storage',
      price: '$90-$250',
      category: 'Nursery',
      image: require('../../assets/images/Categories_Changing_Table.png'),
      rating: 4.3,
      trainingRequired: false,
      trialSuggested: false
    },
    {
      id: 'prod-6',
      title: 'Baby Bottles', 
      description: 'Anti-colic newborn bottle set',
      price: '$20-$60',
      category: 'Feeding',
      image: require('../../assets/images/Categories_Bottles.png'),
      rating: 4.8,
      trainingRequired: false,
      trialSuggested: true
    }
  ]);
  
  // Use listId from props or from URL params
  const listId = propListId || params.listId;
  
  // Check if a plan was passed via location state
  const planFromNavigation = location.state?.plan;
  
  // Check if we should open the product details modal (coming from chatbot)
  const openProductDetailsModal = location.state?.openProductDetailsModal || false;
  const selectedItemIdFromNav = location.state?.selectedItemId || null;
  
  // Use the plan data from navigation state or mock data
  const [currentPlan, setCurrentPlan] = useState(planFromNavigation || mockPlans[listId] || null);
  const [hiddenItems, setHiddenItems] = useState([]);
  
  // Initialize items_by_module if it doesn't exist
  useEffect(() => {
    if (currentPlan && !currentPlan.items_by_module) {
      setCurrentPlan(prevPlan => ({
        ...prevPlan,
        items_by_module: {},
        modules: prevPlan.modules || []
      }));
    }
  }, [currentPlan]);
  
  // Handle auto-opening the product details modal when navigated from chatbot
  useEffect(() => {
    if (openProductDetailsModal && selectedItemIdFromNav && currentPlan) {
      // Find the item in the plan by its ID
      let foundItem = null;
      
      Object.entries(currentPlan.items_by_module).forEach(([module, items]) => {
        if (!foundItem) {
          foundItem = items.find(item => item.uuid === selectedItemIdFromNav);
          if (foundItem) {
            // Add mock product recommendations to the item
            foundItem.recommendations = [
              {
                id: 'rec-1',
                name: 'Babyletto Hudson 3-in-1 Convertible Crib',
                price: '$399.99',
                imageUrl: 'https://m.media-amazon.com/images/I/71KbZBlQ2aL._SL1500_.jpg',
                features: [
                  'Space-saving design for smaller rooms',
                  'Converts from crib to toddler bed to daybed',
                  'Made from sustainable New Zealand pine',
                  'GREENGUARD Gold Certified for low chemical emissions',
                  'Mid-century modern design works with most decor styles'
                ]
              },
              {
                id: 'rec-2',
                name: 'IKEA Sniglar Crib',
                price: '$149.99',
                imageUrl: 'https://www.ikea.com/us/en/images/products/sniglar-crib-beech__0419592_pe576389_s5.jpg',
                features: [
                  'Budget-friendly option',
                  'Solid beech wood construction',
                  'Minimalist Scandinavian design',
                  'Compact size perfect for smaller spaces',
                  'Simple assembly'
                ]
              },
              {
                id: 'rec-3',
                name: 'Delta Children Heartland 4-in-1 Convertible Crib',
                price: '$249.99',
                imageUrl: 'https://m.media-amazon.com/images/I/71feiv1+HaL._AC_SL1500_.jpg',
                features: [
                  'Converts to toddler bed, daybed, and full-size bed',
                  'Three-position mattress height adjustment',
                  'JPMA certified for safety',
                  'Available in multiple finishes',
                  'Sturdy wood construction'
                ]
              }
            ];
            
            // Add a brief delay before showing the modal for a smoother transition
            // This allows the page to render first
            setTimeout(() => {
              // Set as selected item and open the modal
              setSelectedItem(foundItem);
              setIsViewDetailsModalVisible(true);
              
              // Add a highlight effect to the recommendations section
              setTimeout(() => {
                // Use the ID we added to the recommendations section for direct targeting
                const targetSection = document.getElementById('recommendations-section');
                
                if (targetSection) {
                  console.log('Found recommendations section to highlight');
                  // Create and apply a temporary highlight effect
                  const highlightEffect = document.createElement('div');
                  highlightEffect.style.position = 'absolute';
                  highlightEffect.style.top = '0';
                  highlightEffect.style.left = '0';
                  highlightEffect.style.right = '0';
                  highlightEffect.style.bottom = '0';
                  highlightEffect.style.backgroundColor = 'rgba(92, 157, 255, 0.2)';
                  highlightEffect.style.borderRadius = '8px';
                  highlightEffect.style.pointerEvents = 'none';
                  highlightEffect.style.animation = 'fadeInOut 1.5s ease-in-out forwards';
                  
                  // Add keyframes for the highlight animation
                  const style = document.createElement('style');
                  style.textContent = `
                    @keyframes fadeInOut {
                      0% { opacity: 0; }
                      40% { opacity: 0.8; }
                      100% { opacity: 0; }
                    }
                  `;
                  document.head.appendChild(style);
                  
                  // Make recommendations section position relative so the highlight can be positioned
                  targetSection.style.position = 'relative';
                  targetSection.appendChild(highlightEffect);
                  
                  // Remove highlight after animation
                  setTimeout(() => {
                    if (targetSection.contains(highlightEffect)) {
                      targetSection.removeChild(highlightEffect);
                    }
                  }, 1600);
                } else {
                  console.log('Recommendations section not found for highlighting');
                }
              }, 500);
            }, 300);
          }
        }
      });
    }
  }, [openProductDetailsModal, selectedItemIdFromNav, currentPlan]);
  
  // Handle form submission for adding a new item
  const handleAddItem = () => {
    form.validateFields()
      .then(values => {
        // Handle the "new module" case
        let selectedModule = values.module;
        if (selectedModule === 'new') {
          // Create a dialog to get the new module name
          selectedModule = prompt('Enter new module name:');
          if (!selectedModule) {
            selectedModule = 'General';
          }
          
          // Add to the plan's modules list if it's not already there
          if (!currentPlan.modules.includes(selectedModule)) {
            setCurrentPlan(prevPlan => ({
              ...prevPlan,
              modules: [...prevPlan.modules, selectedModule]
            }));
          }
        }
        
        const newItem = {
          uuid: 'item-mock-' + Math.random().toString(36).substring(2, 15),
          name: values.name,
          description: values.description || '',
          module: selectedModule || 'General',
          status: 'not_started',
          priority: values.priority || 'nice_to_have',
          trial_suggested: values.trial_suggested,
          training_required: values.training_required,
          building_required: values.building_required,
          latest_date: values.latest_date,
          latest_date_reason: values.latest_date_reason
        };
        
        // Update plan with new item
        const updatedPlan = { ...currentPlan };
        const module = newItem.module;
        
        // Create module if it doesn't exist
        if (!updatedPlan.items_by_module[module]) {
          updatedPlan.items_by_module[module] = [];
        }
        
        // Add item to module
        updatedPlan.items_by_module[module].push(newItem);
        
        // Update stats
        updatedPlan.stats.total_items += 1;
        if (newItem.trial_suggested) updatedPlan.stats.sampling_items += 1;
        if (newItem.training_required) updatedPlan.stats.training_items += 1;
        if (newItem.building_required) updatedPlan.stats.building_items += 1;
        
        updatedPlan.stats.completion_percentage = Math.floor(
          (updatedPlan.stats.completed_items / updatedPlan.stats.total_items) * 100
        );
        
        setCurrentPlan(updatedPlan);
        setIsAddItemModalVisible(false);
        form.resetFields();
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  // Handle toggling an item's completion status
  const handleToggleItemStatus = (itemUuid, currentStatus) => {
    const updatedPlan = { ...currentPlan };
    let found = false;
    let wasCompleted = false;
    
    // Find and update the item
    Object.keys(updatedPlan.items_by_module).forEach(module => {
      if (!found) {
        const index = updatedPlan.items_by_module[module].findIndex(item => item.uuid === itemUuid);
        if (index !== -1) {
          found = true;
          wasCompleted = updatedPlan.items_by_module[module][index].status === 'completed';
          updatedPlan.items_by_module[module][index].status = 
            currentStatus === 'completed' ? 'in_progress' : 'completed';
        }
      }
    });
    
    // Update stats
    if (found) {
      if (wasCompleted) {
        updatedPlan.stats.completed_items -= 1;
      } else {
        updatedPlan.stats.completed_items += 1;
      }
      
      if (updatedPlan.stats.total_items > 0) {
        updatedPlan.stats.completion_percentage = Math.floor(
          (updatedPlan.stats.completed_items / updatedPlan.stats.total_items) * 100
        );
      } else {
        updatedPlan.stats.completion_percentage = 0;
      }
      
      setCurrentPlan(updatedPlan);
      
      // Send a message back to the parent (to be added later for Redux)
      if (location.state?.onGoalUpdate) {
        location.state.onGoalUpdate(updatedPlan);
      }
    }
  };

  // Handle deleting a plan item
  const handleDeleteItem = (itemUuid) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this item?',
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        const updatedPlan = { ...currentPlan };
        let found = false;
        let wasCompleted = false;
        let removedItem = null;
        
        // Find and remove the item
        Object.keys(updatedPlan.items_by_module).forEach(module => {
          if (!found) {
            const index = updatedPlan.items_by_module[module].findIndex(item => item.uuid === itemUuid);
            if (index !== -1) {
              found = true;
              removedItem = updatedPlan.items_by_module[module][index];
              wasCompleted = removedItem.status === 'completed';
              updatedPlan.items_by_module[module].splice(index, 1);
            }
          }
        });
        
        // Update stats
        if (found && removedItem) {
          updatedPlan.stats.total_items -= 1;
          
          if (wasCompleted) {
            updatedPlan.stats.completed_items -= 1;
          }
          
          if (removedItem.trial_suggested) updatedPlan.stats.sampling_items -= 1;
          if (removedItem.training_required) updatedPlan.stats.training_items -= 1;
          if (removedItem.building_required) updatedPlan.stats.building_items -= 1;
          
          if (updatedPlan.stats.total_items > 0) {
            updatedPlan.stats.completion_percentage = Math.floor(
              (updatedPlan.stats.completed_items / updatedPlan.stats.total_items) * 100
            );
          } else {
            updatedPlan.stats.completion_percentage = 0;
          }
          
          setCurrentPlan(updatedPlan);
        }
      }
    });
  };

  // Handle hiding shared item
  const handleHideItem = (e, itemUuid) => {
    e.stopPropagation();
    
    // Add to hidden items
    setHiddenItems([...hiddenItems, itemUuid]);
  };
  
  // Handle sharing an item
  const handleShareItem = (e, item) => {
    e.stopPropagation();
    setSelectedItemForShare(item);
    setShareModalVisible(true);
  };

  // Handle showing item details
  const handleViewItemDetails = (item) => {
    // Set the item in the URL state and navigate to product details page
    history.push({
      pathname: `/plan/${listId}/product/${item.uuid}`,
      state: { 
        planData: currentPlan,
        productData: item
      }
    });
  };

  // Handle adding suggested item
  const handleAddSuggestedItem = (item) => {
    // Create new item
    const newItem = {
      ...item,
      uuid: 'item-mock-' + Math.random().toString(36).substring(2, 15),
      status: 'not_started'
    };
    
    // Update plan with new item
    const updatedPlan = { ...currentPlan };
    const module = newItem.module;
    
    // Create module if it doesn't exist
    if (!updatedPlan.items_by_module[module]) {
      updatedPlan.items_by_module[module] = [];
    }
    
    // Add item to module
    updatedPlan.items_by_module[module].push(newItem);
    
    // Update stats
    updatedPlan.stats.total_items += 1;
    if (newItem.trial_suggested) updatedPlan.stats.sampling_items += 1;
    if (newItem.training_required) updatedPlan.stats.training_items += 1;
    if (newItem.building_required) updatedPlan.stats.building_items += 1;
    
    updatedPlan.stats.completion_percentage = Math.floor(
      (updatedPlan.stats.completed_items / updatedPlan.stats.total_items) * 100
    );
    
    setCurrentPlan(updatedPlan);
  };

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortValue(e.target.value);
  };

  const handleGoBack = () => {
    if (isInExploreModal && onBackToExplore) {
      // Use the callback if we're in an explore modal
      onBackToExplore();
    } else {
      // Otherwise return to the plan page as usual
      history.push('/plan');
    }
  };

  // If plan is not found
  if (!currentPlan) {
    return (
      <div className="error-container">
        <Title level={4}>List Not Found</Title>
        <Paragraph>
          The list you're looking for doesn't exist or you don't have permission to view it.
        </Paragraph>
        <Button 
          icon={<ArrowLeftOutlined />} 
          onClick={handleGoBack}
          className="back-button"
        >
          {isInExploreModal ? 'Back to Explore' : 'Back to Plan'}
        </Button>
      </div>
    );
  }

  // Get all items (handle case where items_by_module might be undefined)
  const allItems = currentPlan.items_by_module ? Object.values(currentPlan.items_by_module).flat() : [];
  
  // Filter items
  const filteredItems = allItems.filter(item => {
    // Skip hidden items unless showHidden is true
    if (hiddenItems.includes(item.uuid) && !showHidden) {
      return false;
    }
    
    // Apply filter
    if (filterValue === 'all') return true;
    if (filterValue === 'must_have') return item.priority === 'must_have';
    if (filterValue === 'nice_to_have') return item.priority === 'nice_to_have';
    if (filterValue === 'not_worth_it') return item.priority === 'not_worth_it';
    if (filterValue === 'shared') return !!item.shared_by;
    if (filterValue === 'my_items') return !item.shared_by;
    if (filterValue === 'completed') return item.status === 'completed';
    if (filterValue === 'not_started') return item.status === 'not_started';
    return true;
  });
  
  // Sort items
  const sortedItems = [...filteredItems].sort((a, b) => {
    if (sortValue === 'alphabetical') {
      return a.name.localeCompare(b.name);
    }
    if (sortValue === 'priority') {
      const priorityOrder = { 'must_have': 0, 'nice_to_have': 1, 'not_worth_it': 2 };
      return priorityOrder[a.priority] - priorityOrder[b.priority];
    }
    if (sortValue === 'status') {
      const statusOrder = { 'completed': 0, 'in_progress': 1, 'not_started': 2 };
      return statusOrder[a.status] - statusOrder[b.status];
    }
    // Default to module
    return 0;
  });

  // Get filtered suggested items
  const filteredSuggestions = suggestedItems.filter(item => {
    // Only show suggestions that match the current modules
    return currentPlan.modules.includes(item.module);
  });
  
  const renderItemCard = (item) => {
    const isHidden = hiddenItems.includes(item.uuid);
    
    return (
      <Card 
        size="small" 
        key={item.uuid} 
        className={`plan-item-card ${isHidden && showHidden ? 'hidden-item' : ''}`}
        style={{ 
          background: item.status === 'completed' ? '#f6ffed' : '#fff', 
          borderColor: item.status === 'completed' ? '#b7eb8f' : '#d9d9d9', 
          opacity: isHidden && showHidden ? 0.6 : 1
        }}
        onClick={() => handleViewItemDetails(item)}
        actions={[
          <Button 
            type="text" 
            icon={<ShareAltOutlined />} 
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleShareItem(e, item);
            }}
            key="share"
          >
            Share
          </Button>,
          item.shared_by ? (
            <Button 
              type="text" 
              icon={<CloseOutlined />} 
              size="small"
              onClick={(e) => handleHideItem(e, item.uuid)}
              disabled={isHidden}
              key="hide"
            >
              Hide
            </Button>
          ) : (
            <Button 
              type="text" 
              danger
              icon={<DeleteOutlined />} 
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteItem(item.uuid);
              }}
              key="delete"
            >
              Delete
            </Button>
          )
        ]}
      >
        <div className="plan-item-content">
          <Checkbox
            checked={item.status === 'completed'}
            onChange={(e) => {
              e.stopPropagation();
              handleToggleItemStatus(item.uuid, item.status);
            }}
          />
          <div className="plan-item-details">
            <div className="plan-item-name">
              {item.name}
              {item.shared_by && (
                <Tag color="blue" className="shared-by-tag">
                  <UserOutlined /> Shared by {item.shared_by.name}
                </Tag>
              )}
            </div>
            <div className="plan-item-meta">
              <div className="plan-item-priority">
                {item.priority === 'must_have' && <Tag color="red">Must Have</Tag>}
                {item.priority === 'nice_to_have' && <Tag color="orange">Nice to Have</Tag>}
                {item.priority === 'not_worth_it' && <Tag color="default">Not Worth It</Tag>}
              </div>
              <div className="plan-item-tags">
                {item.trial_suggested && <Tag color="blue">Try Before Buy</Tag>}
                {item.training_required && <Tag color="purple">Training Required</Tag>}
                {item.building_required && <Tag color="orange">Assembly Required</Tag>}
                {item.latest_date && (
                  <Tooltip title={item.latest_date_reason}>
                    <Tag color="magenta">By {item.latest_date}</Tag>
                  </Tooltip>
                )}
              </div>
            </div>
            {item.description && (
              <Text type="secondary" className="plan-item-description">
                {item.description}
              </Text>
            )}
          </div>
          <InfoCircleOutlined className="item-info-icon" />
        </div>
      </Card>
    );
  };
  
  const renderItemsByModule = () => {
    if (!currentPlan.items_by_module) {
      return <Empty description="No items found. Click 'Add Item' to add your first item." />;
    }
    
    return Object.entries(currentPlan.items_by_module).map(([module, items]) => {
      // Filter items in this module
      const moduleItems = items.filter(item => {
        if (hiddenItems.includes(item.uuid) && !showHidden) {
          return false;
        }
        
        if (filterValue === 'all') return true;
        if (filterValue === 'must_have') return item.priority === 'must_have';
        if (filterValue === 'nice_to_have') return item.priority === 'nice_to_have';
        if (filterValue === 'not_worth_it') return item.priority === 'not_worth_it';
        if (filterValue === 'shared') return !!item.shared_by;
        if (filterValue === 'my_items') return !item.shared_by;
        if (filterValue === 'completed') return item.status === 'completed';
        if (filterValue === 'not_started') return item.status === 'not_started';
        return true;
      });
      
      if (moduleItems.length === 0) return null;
      
      // Sort items in this module
      const sortedModuleItems = [...moduleItems].sort((a, b) => {
        if (sortValue === 'alphabetical') {
          return a.name.localeCompare(b.name);
        }
        if (sortValue === 'priority') {
          const priorityOrder = { 'must_have': 0, 'nice_to_have': 1, 'not_worth_it': 2 };
          return priorityOrder[a.priority] - priorityOrder[b.priority];
        }
        if (sortValue === 'status') {
          const statusOrder = { 'completed': 0, 'in_progress': 1, 'not_started': 2 };
          return statusOrder[a.status] - statusOrder[b.status];
        }
        return 0;
      });
      
      return (
        <div key={module} className="plan-module-section">
          <Title level={5}>{module}</Title>
          <Space direction="vertical" style={{ width: '100%' }}>
            {sortedModuleItems.map(renderItemCard)}
          </Space>
        </div>
      );
    });
  };

  return (
    <div className="plan-details-container">
      <div className="plan-details-header">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
          <Breadcrumb style={{ fontSize: '14px' }}>
            <Breadcrumb.Item href="/plan">
              <HomeOutlined /> Plans
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{currentPlan.name}</span>
            </Breadcrumb.Item>
            {selectedItem && (
              <Breadcrumb.Item>
                <span>{selectedItem.name}</span>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
          
          <Button icon={<ArrowLeftOutlined />} onClick={handleGoBack}>
            {isInExploreModal ? 'Back to Explore' : 'Back to Plan'}
          </Button>
        </div>
      </div>
      
      <Card className="plan-details-card">
        <div className="plan-details-main">
          <div>
            <div className="plan-title-section">
              <Title level={2}>{currentPlan.name}</Title>
              <Button 
                type="primary" 
                icon={<PlusOutlined />}
                onClick={() => setIsAddItemModalVisible(true)}
              >
                Add Product
              </Button>
            </div>
            <Paragraph>{currentPlan.description}</Paragraph>
            
            <div className="plan-modules">
              {currentPlan.modules.map((module, idx) => (
                <Tag key={idx}>{module}</Tag>
              ))}
            </div>
          </div>
          <div className="plan-stats">
            <div className="progress-stats">
              <Text type="secondary">Progress</Text>
              <Progress 
                percent={currentPlan.stats.completion_percentage} 
                size="small"
                status="active"
              />
              <Text type="secondary">
                {currentPlan.stats.completed_items} of {currentPlan.stats.total_items} products completed
              </Text>
            </div>
            
            <div className="stats-counts">
              <Row gutter={[16, 8]}>
                {currentPlan.stats.sampling_items > 0 && (
                  <Col span={24}>
                    <div className="stat-item">
                      <div className="stat-count">{currentPlan.stats.sampling_items}</div>
                      <div className="stat-label">products where sampling/trial is suggested</div>
                    </div>
                  </Col>
                )}
                {currentPlan.stats.training_items > 0 && (
                  <Col span={24}>
                    <div className="stat-item">
                      <div className="stat-count">{currentPlan.stats.training_items}</div>
                      <div className="stat-label">products where training is required</div>
                    </div>
                  </Col>
                )}
                {currentPlan.stats.building_items > 0 && (
                  <Col span={24}>
                    <div className="stat-item">
                      <div className="stat-count">{currentPlan.stats.building_items}</div>
                      <div className="stat-label">products where building is required</div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
        
        <Divider />
        
        <div className="filter-controls">
          <div className="filter-section">
            <Text strong>Filter By:</Text>
            <Radio.Group value={filterValue} onChange={handleFilterChange} buttonStyle="solid" size="small">
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value="must_have">Must Have</Radio.Button>
              <Radio.Button value="nice_to_have">Nice to Have</Radio.Button>
              <Radio.Button value="shared">Shared</Radio.Button>
              <Radio.Button value="my_items">My Items</Radio.Button>
              <Radio.Button value="completed">Completed</Radio.Button>
            </Radio.Group>
          </div>
          
          <div className="filter-section">
            <Text strong>Sort By:</Text>
            <Radio.Group value={sortValue} onChange={handleSortChange} buttonStyle="solid" size="small">
              <Radio.Button value="module">Module</Radio.Button>
              <Radio.Button value="alphabetical">Name</Radio.Button>
              <Radio.Button value="priority">Priority</Radio.Button>
              <Radio.Button value="status">Status</Radio.Button>
            </Radio.Group>
          </div>
          
          <div className="filter-section">
            <Button 
              type={showHidden ? "primary" : "default"} 
              size="small"
              onClick={() => setShowHidden(!showHidden)}
            >
              {showHidden ? "Hide Hidden Items" : "Show Hidden Items"}
            </Button>
          </div>
        </div>
        
        <div className="plan-items-section">
          {sortValue === 'module' ? (
            <div className="items-by-module">
              {renderItemsByModule()}
            </div>
          ) : (
            <Space direction="vertical" style={{ width: '100%' }}>
              {sortedItems.length > 0 ? (
                sortedItems.map(renderItemCard)
              ) : (
                <Empty 
                  description="No products in this list yet" 
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </Space>
          )}

          {/* Empty state showing add products button */}
          {sortedItems.length === 0 && (
            <div className="empty-plans" style={{ textAlign: 'center', padding: '24px', marginTop: '16px' }}>
              <Button 
                type="primary" 
                icon={<PlusOutlined />}
                onClick={() => setIsAddItemModalVisible(true)}
                style={{ 
                  marginTop: '16px',
                  background: '#5c9dff',
                  borderColor: '#4b87e8',
                  borderRadius: '18px',
                  height: '36px',
                  boxShadow: '0 3px 0 rgba(76, 111, 255, 0.1)'
                }}
              >
                Add Products
              </Button>
            </div>
          )}
        </div>
      </Card>

      {/* Add Products Modal with Recommended Products */}
      <Modal
        title="Add Products to List"
        open={isAddItemModalVisible}
        onCancel={() => setIsAddItemModalVisible(false)}
        modalRender={(modal) => (
          <div style={{
            opacity: modalFadeState === 'fadeOut' ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out'
          }}>
            {modal}
          </div>
        )}
        onOk={() => {
          // Extract selected products and add them
          const selectedProducts = recommendedProducts.filter(item => selectedProductIds.includes(item.id));
          
          // Add all selected products to the list
          selectedProducts.forEach(product => {
            const newItem = {
              uuid: 'item-mock-' + Math.random().toString(36).substring(2, 15),
              name: product.title,
              description: product.description,
              module: product.category || 'General',
              status: 'not_started',
              priority: 'must_have',
              trial_suggested: product.trialSuggested,
              training_required: product.trainingRequired
            };
            
            handleAddSuggestedItem(newItem);
          });
          
          // Close modal and reset selections
          setIsAddItemModalVisible(false);
          setSelectedProductIds([]);
          
          // Show success message
          message.success(`Added ${selectedProductIds.length} product${selectedProductIds.length !== 1 ? 's' : ''} to list`);
        }}
        okText={`Add ${selectedProductIds.length || 'All'} Products to List`}
        okButtonProps={{ 
          disabled: selectedProductIds.length === 0,
          style: { 
            background: '#5c9dff',
            borderColor: '#4b87e8'
          }
        }}
        width={800}
      >
        <div className="recommended-products-container">
          <div className="section-title">
            <Title level={4} style={{ fontSize: '16px', margin: '0 0 16px 0' }}>Recommended Products for Emma</Title>
            
            <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text type="secondary">Select products to add to your list:</Text>
              
              <div>
                <Button 
                  type="link" 
                  size="small"
                  onClick={() => setSelectedProductIds(recommendedProducts.map(p => p.id))}
                >
                  Select All
                </Button>
                <Button 
                  type="link" 
                  size="small"
                  onClick={() => setSelectedProductIds([])}
                  disabled={selectedProductIds.length === 0}
                >
                  Clear All
                </Button>
                {/* Button to toggle between empty state and products (for demo only) */}
                <Button 
                  type="link" 
                  size="small"
                  onClick={() => setShowEmptyProductsState(!showEmptyProductsState)}
                  style={{ marginLeft: '8px' }}
                >
                  {showEmptyProductsState ? "Show Products (Demo)" : "Show Empty State"}
                </Button>
              </div>
            </div>
          </div>
          
          {!showEmptyProductsState ? (
            <List
              grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3 }}
              dataSource={recommendedProducts || [
              {
                id: 'prod-1',
                title: 'Baby Car Seat', 
                description: 'Safe and comfortable infant car seat',
                price: '$149.99',
                category: 'Safety',
                image: require('../../assets/images/Categories_CarSeat.png'),
                rating: 4.7,
                trialSuggested: true,
                trainingRequired: true
              },
              {
                id: 'prod-2',
                title: 'Convertible Crib', 
                description: 'Grows with your baby from crib to toddler bed',
                price: '$299.99',
                category: 'Nursery',
                image: require('../../assets/images/Categories_Crib.png'),
                rating: 4.5,
                trainingRequired: false,
                trialSuggested: false
              },
              {
                id: 'prod-3',
                title: 'Baby Monitor', 
                description: 'HD video and audio monitoring',
                price: '$129.99',
                category: 'Safety',
                image: require('../../assets/images/Categories_Baby_Monitor.png'),
                rating: 4.6,
                trainingRequired: true,
                trialSuggested: false
              },
              {
                id: 'prod-4',
                title: 'Stroller', 
                description: 'Lightweight and easy to fold travel stroller',
                price: '$179.99',
                category: 'Travel',
                image: require('../../assets/images/Categories_Strollers.png'),
                rating: 4.4,
                trainingRequired: false,
                trialSuggested: true
              },
              {
                id: 'prod-5',
                title: 'Changing Table', 
                description: 'Sturdy changing table with storage',
                price: '$149.99',
                category: 'Nursery',
                image: require('../../assets/images/Categories_Changing_Table.png'),
                rating: 4.3,
                trainingRequired: false,
                trialSuggested: false
              },
              {
                id: 'prod-6',
                title: 'Baby Bottles', 
                description: 'Anti-colic newborn bottle set',
                price: '$29.99',
                category: 'Feeding',
                image: require('../../assets/images/Categories_Bottles.png'),
                rating: 4.8,
                trainingRequired: false,
                trialSuggested: true
              }
            ]}
            renderItem={item => (
              <List.Item key={item.id}>
                <Card
                  hoverable
                  style={{ 
                    width: '100%', 
                    borderRadius: '8px',
                    border: selectedProductIds.includes(item.id) ? '2px solid #5c9dff' : '1px solid #e8e8e8',
                    background: selectedProductIds.includes(item.id) ? '#f0f7ff' : '#fff',
                    overflow: 'hidden'
                  }}
                  cover={
                    <div style={{ 
                      height: 140, 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#f5f8ff',
                      padding: '15px',
                      position: 'relative',
                      width: '100%',
                      boxSizing: 'border-box',
                      overflow: 'hidden',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px'
                    }}>
                      <div style={{ 
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          zIndex: 2,
                          background: 'rgba(255, 255, 255, 0.8)',
                          borderRadius: '4px',
                          padding: '2px',
                          boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                          transform: 'translateX(0)'
                        }}>
                        <Checkbox
                          checked={selectedProductIds.includes(item.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedProductIds([...selectedProductIds, item.id]);
                            } else {
                              setSelectedProductIds(selectedProductIds.filter(id => id !== item.id));
                            }
                          }}
                        />
                      </div>
                      <img 
                        alt={item.title}
                        src={item.image}
                        style={{ maxHeight: '120px', maxWidth: '100%', objectFit: 'contain' }}
                      />
                    </div>
                  }
                  onClick={() => {
                    // Toggle selection when clicking the card
                    if (selectedProductIds.includes(item.id)) {
                      setSelectedProductIds(selectedProductIds.filter(id => id !== item.id));
                    } else {
                      setSelectedProductIds([...selectedProductIds, item.id]);
                    }
                  }}
                >
                  <div style={{ padding: '8px 4px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <Title level={5} style={{ fontSize: '14px', margin: '0 0 4px 0', lineHeight: 1.3 }}>{item.title}</Title>
                      <Text strong style={{ color: '#5c9dff', fontSize: '14px' }}>{item.price}</Text>
                    </div>
                    <Text type="secondary" style={{ fontSize: '12px', lineHeight: 1.3, display: 'block', marginBottom: '8px' }}>
                      {item.description}
                    </Text>
                    <div>
                      <Tag color="blue">{item.category}</Tag>
                      {item.trialSuggested && <Tag color="green">Try Before Buy</Tag>}
                      {item.trainingRequired && <Tag color="orange">Training Required</Tag>}
                    </div>
                  </div>
                </Card>
              </List.Item>
            )}
          />
          ) : (
            /* Empty State for No Products */
            <div className="empty-plans" style={{ textAlign: 'center', padding: '24px', marginTop: '12px' }}>
              <Title level={4} className="empty-plans-title">No suggested products</Title>
              <Paragraph className="empty-plans-description">
                We're working on personalized product recommendations for your baby registry
              </Paragraph>
              <Button 
                type="primary" 
                icon={<PlusOutlined />}
                onClick={() => {
                  // Close the modal
                  setIsAddItemModalVisible(false);
                  
                  // Create a custom event to show chat drawer with context
                  const event = new CustomEvent('showChatDrawer', { 
                    detail: { 
                      fromProductModal: true,
                      forProductSuggestion: true,
                      returnToProductModal: true,
                      requestType: 'productRecommendations',
                      actionLabel: 'See Recommendations',
                      babyName: "Emma", // Use baby name from context
                      activateProductExpert: true,
                      // Auto-message details
                      autoMessage: true,
                      messageText: "Can you please suggest products for my nursery list?",
                      messageAnimationDuration: 250, // Bold text for 0.25 seconds
                      sendAfterAnimation: true, // Auto-send after animation
                      returnFunction: () => {
                        // This function will be called when "See Recommendations" is clicked
                        setShowEmptyProductsState(false);
                        setIsAddItemModalVisible(true);
                      }
                    }
                  });
                  
                  // Dispatch the event to be caught by MainNavigation component
                  window.dispatchEvent(event);
                  
                  // We'll now use the returnFunction attached to the event detail
                  // instead of using a timeout to simulate the return
                  // This function will be called when "See Recommendations" is clicked in the chat
                }}
                style={{ 
                  marginTop: '16px',
                  background: '#5c9dff',
                  borderColor: '#4b87e8',
                  borderRadius: '18px',
                  height: '36px',
                  boxShadow: '0 3px 0 rgba(76, 111, 255, 0.1)'
                }}
              >
                Get Nursery Product Suggestions
              </Button>
            </div>
          )}
          
          {/* Add Custom Product Button */}
          <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <Button 
              type="link" 
              onClick={() => {
                // Switch to custom product form
                // Start fade out animation
                setModalFadeState('fadeOut');
                
                // After fadeOut completes, switch modals
                setTimeout(() => {
                  setIsAddItemModalVisible(false);
                  form.resetFields();
                  setIsAddItemCustomModalVisible(true);
                  // Start fade in animation for the new modal
                  setTimeout(() => {
                    setModalFadeState('fadeIn');
                    // Reset to normal state after animation completes
                    setTimeout(() => {
                      setModalFadeState('visible');
                    }, 300);
                  }, 50);
                }, 300);
              }}
            >
              Add something else
            </Button>
          </div>
        </div>
      </Modal>
      
      {/* Custom Product Modal */}
      <Modal
        title="Add Custom Product"
        open={isAddItemCustomModalVisible}
        onCancel={() => setIsAddItemCustomModalVisible(false)}
        modalRender={(modal) => (
          <div style={{
            opacity: modalFadeState === 'fadeIn' ? 0 : 1,
            animation: modalFadeState === 'fadeIn' ? 'fadeInAnimation 0.3s ease-in-out forwards' : 'none',
            transition: 'opacity 0.3s ease-in-out'
          }}>
            {modal}
          </div>
        )}
        onOk={handleAddItem}
        okText="Add Custom Product"
        width={700}
      >
        <Form
          form={form}
          layout="vertical"
          name="item_form"
        >
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Product Name"
                rules={[{ required: true, message: 'Please enter a product name' }]}
              >
                <Input placeholder="Enter product name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="module"
                label="Category"
              >
                <Select placeholder="Select a category">
                  {currentPlan.modules.map(module => (
                    <Option key={module} value={module}>{module}</Option>
                  ))}
                  <Option key="new-module" value="new">+ Add New Category</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          
          <Form.Item
            name="description"
            label="Description"
          >
            <TextArea
              placeholder="Enter product description"
              rows={3}
            />
          </Form.Item>
          
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="priority"
                label="Priority"
              >
                <Select placeholder="Select priority">
                  <Option value="must_have">Must Have</Option>
                  <Option value="nice_to_have">Nice to Have</Option>
                  <Option value="not_worth_it">Not Worth It</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="latest_date"
                label="Need By Date"
              >
                <Input placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="latest_date_reason"
                label="Date Reason"
              >
                <Input placeholder="Why this date?" />
              </Form.Item>
            </Col>
          </Row>
          
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="trial_suggested"
                valuePropName="checked"
              >
                <Checkbox>Try Before Buy</Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="training_required"
                valuePropName="checked"
              >
                <Checkbox>Training Required</Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="building_required"
                valuePropName="checked"
              >
                <Checkbox>Assembly Required</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      
      {/* View Item Details Modal */}
      {selectedItem && (
        <Modal
          title={selectedItem.name}
          open={isViewDetailsModalVisible}
          onCancel={() => {
            setIsViewDetailsModalVisible(false);
            setSelectedItem(null);
          }}
          footer={[
            <Button 
              key="close" 
              onClick={() => {
                setIsViewDetailsModalVisible(false);
                setSelectedItem(null);
              }}
            >
              Close
            </Button>
          ]}
          width={600}
        >
          <div className="item-details-content">
            {selectedItem.description && (
              <div className="item-detail-section">
                <Title level={5}>Description</Title>
                <Paragraph>{selectedItem.description}</Paragraph>
              </div>
            )}
            
            <div className="item-detail-section">
              <Title level={5}>Details</Title>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Text strong>Status: </Text>
                  {selectedItem.status === 'completed' && <Tag color="success">Completed</Tag>}
                  {selectedItem.status === 'in_progress' && <Tag color="processing">In Progress</Tag>}
                  {selectedItem.status === 'not_started' && <Tag color="default">Not Started</Tag>}
                </Col>
                <Col span={12}>
                  <Text strong>Priority: </Text>
                  {selectedItem.priority === 'must_have' && <Tag color="red">Must Have</Tag>}
                  {selectedItem.priority === 'nice_to_have' && <Tag color="orange">Nice to Have</Tag>}
                  {selectedItem.priority === 'not_worth_it' && <Tag color="default">Not Worth It</Tag>}
                </Col>
                <Col span={12}>
                  <Text strong>Module: </Text>
                  <Tag>{selectedItem.module}</Tag>
                </Col>
                {selectedItem.shared_by && (
                  <Col span={12}>
                    <Text strong>Shared By: </Text>
                    <Tag color="blue">
                      <UserOutlined /> {selectedItem.shared_by.name}
                    </Tag>
                  </Col>
                )}
              </Row>
            </div>
            
            {(selectedItem.trial_suggested || selectedItem.training_required || selectedItem.building_required) && (
              <div className="item-detail-section">
                <Title level={5}>Requirements</Title>
                <Row gutter={[16, 16]}>
                  {selectedItem.trial_suggested && (
                    <Col span={24}>
                      <div className="requirement-item">
                        <Tag color="blue">Try Before Buy</Tag>
                        <Text>You might want to sample or try this item before purchasing</Text>
                      </div>
                    </Col>
                  )}
                  {selectedItem.training_required && (
                    <Col span={24}>
                      <div className="requirement-item">
                        <Tag color="purple">Training Required</Tag>
                        <Text>You'll need to learn how to use this item properly</Text>
                      </div>
                    </Col>
                  )}
                  {selectedItem.building_required && (
                    <Col span={24}>
                      <div className="requirement-item">
                        <Tag color="orange">Assembly Required</Tag>
                        <Text>This item requires assembly or installation</Text>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            )}
            
            {selectedItem.latest_date && (
              <div className="item-detail-section">
                <Title level={5}>Timeline</Title>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div className="requirement-item">
                      <Tag color="magenta">By {selectedItem.latest_date}</Tag>
                      <Text>{selectedItem.latest_date_reason || 'This item should be acquired by this date'}</Text>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            
            {/* Suggested Products Section - Show for all products */}
            <div className="item-detail-section" id="recommendations-section">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <Title level={5} style={{ margin: 0 }}>Suggested Products</Title>
                {/* Demo button to toggle between empty state and recommendations */}
                <Button 
                  type="link" 
                  size="small"
                  onClick={() => setShowEmptyRecommendationsState(!showEmptyRecommendationsState)}
                >
                  {showEmptyRecommendationsState ? "Show Recommendations (Demo)" : "Show Empty State"}
                </Button>
              </div>
              
              {!showEmptyRecommendationsState && selectedItem && selectedItem.recommendations && selectedItem.recommendations.length > 0 ? (
                // Show recommendations if available
                <div style={{ marginTop: '15px' }}>
                  <List
                    itemLayout="horizontal"
                    dataSource={selectedItem.recommendations}
                    renderItem={item => (
                      <List.Item key={item.id}>
                        <Card 
                          style={{ width: '100%', marginBottom: '10px' }}
                          hoverable
                        >
                          <div style={{ display: 'flex', gap: '15px' }}>
                            <div style={{ width: '120px', flexShrink: 0 }}>
                              <img 
                                src={item.imageUrl} 
                                alt={item.name} 
                                style={{ width: '100%', height: 'auto', borderRadius: '4px' }} 
                              />
                            </div>
                            <div style={{ flex: 1 }}>
                              <Title level={5} style={{ margin: '0 0 5px 0' }}>{item.name}</Title>
                              <Text type="secondary">{item.price}</Text>
                              <ul style={{ paddingLeft: '20px', marginTop: '8px' }}>
                                {item.features.slice(0, 3).map((feature, idx) => (
                                  <li key={idx} style={{ fontSize: '12px', marginBottom: '3px' }}>
                                    {feature}
                                  </li>
                                ))}
                              </ul>
                              <div style={{ marginTop: '10px' }}>
                                <Button type="primary" size="small">
                                  Add to Cart
                                </Button>
                                <Button size="small" style={{ marginLeft: '8px' }}>
                                  View Details
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </List.Item>
                    )}
                  />
                  <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    <Text type="secondary">Recommended by Bibi based on your needs</Text>
                  </div>
                </div>
              ) : (
                <>
                  {/* Show empty state with button to get recommendations */}
                  <div className="empty-plans" style={{ textAlign: 'center', padding: '24px', marginTop: '12px' }}>
                    <Title level={4} className="empty-plans-title">No suggested brands/models</Title>
                    <Paragraph className="empty-plans-description">
                      We're working on personalized brand and model recommendations for this product
                    </Paragraph>
                    <Button 
                      type="primary" 
                      icon={<PlusOutlined />}
                      onClick={() => {
                        // Close the modal
                        setIsViewDetailsModalVisible(false);
                        setSelectedItem(null);
                        
                        // Create a custom event to show chat drawer with context
                        const event = new CustomEvent('showChatDrawer', { 
                          detail: { 
                            fromPlanDetails: true,
                            forProductSuggestion: true,
                            requestType: 'brandModelRecommendations',
                            actionLabel: 'See Recommendations',
                            productName: selectedItem ? selectedItem.name : 'product', // Use the current item name
                            babyName: "Emma", // Use baby name from context
                            activateProductExpert: true,
                            // Auto-message details
                            autoMessage: true,
                            messageText: `Can you recommend some good ${selectedItem ? selectedItem.name : 'nursery furniture'} brands and models for my nursery?`,
                            messageAnimationDuration: 250, // Bold text for 0.25 seconds
                            sendAfterAnimation: true, // Auto-send after animation
                            returnFunction: () => {
                              // This should add mock recommendations to the selected item and reopen the modal
                              const updatedItem = { ...selectedItem };
                              
                              // Add mock recommendations if they don't exist
                              if (!updatedItem.recommendations || updatedItem.recommendations.length === 0) {
                                updatedItem.recommendations = [
                                  {
                                    id: 'rec-1',
                                    name: 'Babyletto Hudson 3-in-1 Convertible Crib',
                                    price: '$399.99',
                                    imageUrl: 'https://m.media-amazon.com/images/I/71KbZBlQ2aL._SL1500_.jpg',
                                    features: [
                                      'Space-saving design for smaller rooms',
                                      'Converts from crib to toddler bed to daybed',
                                      'Made from sustainable New Zealand pine',
                                      'GREENGUARD Gold Certified for low chemical emissions',
                                      'Mid-century modern design works with most decor styles'
                                    ]
                                  },
                                  {
                                    id: 'rec-2',
                                    name: 'IKEA Sniglar Crib',
                                    price: '$149.99',
                                    imageUrl: 'https://www.ikea.com/us/en/images/products/sniglar-crib-beech__0419592_pe576389_s5.jpg',
                                    features: [
                                      'Budget-friendly option',
                                      'Solid beech wood construction',
                                      'Minimalist Scandinavian design',
                                      'Compact size perfect for smaller spaces',
                                      'Simple assembly'
                                    ]
                                  }
                                ];
                              }
                              
                              // Update selected item, show recommendations, and reopen the modal
                              setSelectedItem(updatedItem);
                              setShowEmptyRecommendationsState(false); // Show the recommendations instead of empty state
                              setIsViewDetailsModalVisible(true);
                            }
                          }
                        });
                        
                        // Dispatch the event to be caught by MainNavigation component
                        window.dispatchEvent(event);
                      }}
                      style={{ 
                        marginTop: '16px',
                        background: '#5c9dff',
                        borderColor: '#4b87e8',
                        borderRadius: '18px',
                        height: '36px',
                        boxShadow: '0 3px 0 rgba(76, 111, 255, 0.1)'
                      }}
                    >
                      Get Nursery Product Suggestions
                    </Button>
                  </div>
                  
                  {/* Responsive product cards grid */}
                  <div style={{ marginTop: '30px' }}>
                    <Divider>
                      <Text type="secondary">All {selectedItem ? selectedItem.name : 'Products'}</Text>
                    </Divider>
                    
                    <Row gutter={[16, 16]} style={{ marginTop: '12px' }}>
                      {/* Sample product cards */}
                      {[
                        {
                          id: 'pop-1',
                          name: selectedItem && selectedItem.name.toLowerCase().includes('crib') ? 'DaVinci Kalani 4-in-1 Crib' : 'Ergobaby Carrier',
                          price: selectedItem && selectedItem.name.toLowerCase().includes('crib') ? '$179.99' : '$120.00',
                          imageUrl: selectedItem && selectedItem.name.toLowerCase().includes('crib') 
                            ? 'https://m.media-amazon.com/images/I/71K5NJ-Ua3L._SL1500_.jpg'
                            : 'https://m.media-amazon.com/images/I/71Vr9GQ2s9L._SL1500_.jpg',
                          rating: 4.7,
                          reviews: 2340,
                          registries: ['Babylist', 'Amazon Baby']
                        },
                        {
                          id: 'pop-2',
                          name: selectedItem && selectedItem.name.toLowerCase().includes('crib') ? 'Graco Benton 4-in-1 Crib' : 'Britax B-Safe Infant Car Seat',
                          price: selectedItem && selectedItem.name.toLowerCase().includes('crib') ? '$149.99' : '$199.99',
                          imageUrl: selectedItem && selectedItem.name.toLowerCase().includes('crib')
                            ? 'https://m.media-amazon.com/images/I/71vHxTZBfoL._SL1500_.jpg'
                            : 'https://m.media-amazon.com/images/I/71BWsz2prOL._SL1500_.jpg',
                          rating: 4.5,
                          reviews: 1820,
                          registries: ['Target Baby', 'BuyBuy Baby', 'Amazon Baby']
                        },
                        {
                          id: 'pop-3',
                          name: selectedItem && selectedItem.name.toLowerCase().includes('crib') ? 'IKEA Gulliver Crib' : 'UPPAbaby VISTA Stroller',
                          price: selectedItem && selectedItem.name.toLowerCase().includes('crib') ? '$119.99' : '$899.99',
                          imageUrl: selectedItem && selectedItem.name.toLowerCase().includes('crib')
                            ? 'https://www.ikea.com/us/en/images/products/gulliver-crib-white__0637931_pe698633_s5.jpg'
                            : 'https://m.media-amazon.com/images/I/71qy3xgBQLL._SL1500_.jpg',
                          rating: 4.3,
                          reviews: 980,
                          registries: ['Babylist', 'IKEA Registry']
                        }
                      ].map(product => (
                        <Col xs={24} sm={12} md={8} key={product.id}>
                          <Card
                            hoverable
                            cover={
                              <div style={{ height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '12px', background: '#f9f9f9' }}>
                                <img
                                  alt={product.name}
                                  src={product.imageUrl}
                                  style={{ maxHeight: '160px', maxWidth: '100%', objectFit: 'contain' }}
                                />
                              </div>
                            }
                            bodyStyle={{ padding: '12px' }}
                          >
                            <div style={{ marginBottom: '8px', height: '40px', overflow: 'hidden' }}>
                              <Text strong style={{ fontSize: '14px' }}>{product.name}</Text>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Text type="secondary" strong style={{ fontSize: '16px', color: '#5c9dff' }}>{product.price}</Text>
                              <div>
                                <Rate disabled defaultValue={product.rating} style={{ fontSize: '12px' }} />
                                <Text type="secondary" style={{ fontSize: '12px', marginLeft: '4px' }}>({product.reviews})</Text>
                              </div>
                            </div>
                            
                            {/* Registry tags */}
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginTop: '10px', marginBottom: '10px' }}>
                              {product.registries.map((registry, idx) => (
                                <Tag 
                                  key={idx} 
                                  style={{ 
                                    borderRadius: '12px', 
                                    backgroundColor: '#f0f5ff', 
                                    color: '#5c9dff',
                                    border: '1px solid #d9e6ff',
                                    fontSize: '11px',
                                    padding: '0 8px'
                                  }}
                                >
                                  {registry}
                                </Tag>
                              ))}
                            </div>
                            
                            <Button 
                              type="primary" 
                              block 
                              style={{ marginTop: '4px', background: '#5c9dff', borderColor: '#4b87e8' }}
                            >
                              View Details
                            </Button>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </>
              )}
            </div>
            
            <div className="item-detail-actions">
              {selectedItem.shared_by ? (
                <Button 
                  danger
                  icon={<CloseOutlined />}
                  onClick={() => {
                    handleHideItem({ stopPropagation: () => {} }, selectedItem.uuid);
                    setIsViewDetailsModalVisible(false);
                    setSelectedItem(null);
                  }}
                >
                  Hide Item
                </Button>
              ) : (
                <Button 
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleDeleteItem(selectedItem.uuid);
                    setIsViewDetailsModalVisible(false);
                    setSelectedItem(null);
                  }}
                >
                  Delete Item
                </Button>
              )}
            </div>
          </div>
        </Modal>
      )}
      
      {/* Share Modal */}
      {selectedItemForShare && (
        <ShareModal
          visible={shareModalVisible}
          onCancel={() => setShareModalVisible(false)}
          itemType="product"
          itemName={selectedItemForShare.name}
          itemId={selectedItemForShare.uuid}
        />
      )}
      
      {/* Add space at the bottom to prevent content being hidden by the navigation menu */}
      <div style={{ height: '120px' }}></div>
    </div>
  );
};

export default PlanDetails;