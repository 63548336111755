import React, { useState, useEffect, useRef } from 'react';
import { Typography, Input, Button, Avatar, Spin, List, Card, Divider, Tag, Tabs, Modal } from 'antd';
import ReactMarkdown from 'react-markdown';
import { 
  SendOutlined, 
  RobotOutlined, 
  UserOutlined, 
  BulbOutlined, 
  ShoppingOutlined, 
  QuestionCircleOutlined,
  SmileOutlined,
  HeartOutlined,
  MedicineBoxOutlined,
  SafetyOutlined,
  ExperimentOutlined,
  AlertOutlined,
  DollarOutlined,
  BankOutlined,
  PieChartOutlined,
  CalculatorOutlined,
  UpOutlined,
  DownOutlined
} from '@ant-design/icons';
import useChatGPT from '../redux/chatgpt/hooks';
import '../../assets/css/components/chat.css';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

// Sample suggested questions for product expert
const productExpertQuestions = [
  "What items do I really need for a newborn?",
  "How do I choose the right stroller?",
  "Which bottles are best for breastfed babies?",
  "What should I look for in a car seat?",
  "When should I start buying baby items?",
  "Are expensive baby monitors worth it?",
];

// Sample suggested questions for DBT therapist
const therapistQuestions = [
  "How can I manage anxiety during pregnancy?",
  "What are some mindfulness techniques I can practice?",
  "How do I set healthy boundaries with family?",
  "I'm feeling overwhelmed, what DBT skills can help?",
  "Can you explain emotion regulation in DBT?",
  "How do I practice radical acceptance?",
];

// Sample suggested questions for pregnancy medical info
const medicalQuestions = [
  "What foods should I avoid during pregnancy?",
  "Is it normal to have cramping in early pregnancy?",
  "How much weight should I gain during pregnancy?",
  "What prenatal vitamins are most important?",
  "What symptoms indicate I should call my doctor?",
  "Are there safe medications for headaches during pregnancy?",
];

// Sample suggested questions for financial advisor
const financialQuestions = [
  "How should I budget for a new baby?",
  "What financial planning should I do before my baby arrives?",
  "How much does childcare typically cost?",
  "What tax benefits are available for new parents?",
  "Should I start a college fund right away?",
  "How do I balance saving for retirement and childcare costs?",
];

const Chat = () => {
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = useState('product-expert');
  const [productChatHistory, setProductChatHistory] = useState([]);
  const [therapistChatHistory, setTherapistChatHistory] = useState([]);
  const [medicalChatHistory, setMedicalChatHistory] = useState([]);
  const [financialChatHistory, setFinancialChatHistory] = useState([]);
  const [suggestionsPopupVisible, setSuggestionsPopupVisible] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionVisible, setQuestionVisible] = useState(true);
  
  // Get questions for current tab
  const getQuestionsForTab = () => {
    switch(activeTab) {
      case 'product-expert': return productExpertQuestions;
      case 'therapist': return therapistQuestions;
      case 'medical': return medicalQuestions;
      case 'financial': return financialQuestions;
      default: return [];
    }
  };
  
  // Rotate through questions every 5 seconds with fade effect
  useEffect(() => {
    const questions = getQuestionsForTab();
    if (questions.length === 0) return;
    
    // Reset current question when tab changes
    setCurrentQuestion(0);
    setQuestionVisible(true);
    
    const interval = setInterval(() => {
      // Fade out
      setQuestionVisible(false);
      
      // Wait for fade out, then change question and fade in
      setTimeout(() => {
        setCurrentQuestion(prevIndex => (prevIndex + 1) % questions.length);
        setQuestionVisible(true);
      }, 500);
      
    }, 5000);
    
    return () => clearInterval(interval);
  }, [activeTab]);
  const { sendMessage, loading, error } = useChatGPT();
  const messagesEndRef = useRef(null);

  // Current chat history based on active tab
  const getChatHistory = () => {
    switch(activeTab) {
      case 'product-expert':
        return productChatHistory;
      case 'therapist':
        return therapistChatHistory;
      case 'medical':
        return medicalChatHistory;
      case 'financial':
        return financialChatHistory;
      default:
        return productChatHistory;
    }
  };
  
  const setChatHistory = (updater) => {
    switch(activeTab) {
      case 'product-expert':
        return setProductChatHistory(updater);
      case 'therapist':
        return setTherapistChatHistory(updater);
      case 'medical':
        return setMedicalChatHistory(updater);
      case 'financial':
        return setFinancialChatHistory(updater);
      default:
        return setProductChatHistory(updater);
    }
  };
  
  const chatHistory = getChatHistory();

  // Sample first messages for each chatbot
  useEffect(() => {
    // Product expert initial message
    if (productChatHistory.length === 0) {
      setProductChatHistory([
        {
          role: 'assistant',
          content: "Hi there! I'm your Bibi product expert assistant. I can help you with product recommendations, answer questions about baby gear, or provide information about different stages of pregnancy and baby development. How can I help you today?",
          timestamp: new Date(),
        }
      ]);
    }
    
    // Therapist initial message
    if (therapistChatHistory.length === 0) {
      setTherapistChatHistory([
        {
          role: 'assistant',
          content: "Hello! I'm your Bibi DBT therapist assistant. I'm here to help you apply dialectical behavior therapy (DBT) skills to manage emotions, improve relationships, and navigate the challenges of pregnancy and parenthood. What would you like to explore today?",
          timestamp: new Date(),
        }
      ]);
    }
    
    // Medical assistant initial message
    if (medicalChatHistory.length === 0) {
      setMedicalChatHistory([
        {
          role: 'assistant',
          content: "Hello! I'm your Bibi pregnancy information assistant. I can provide general information about pregnancy, prenatal health, and common concerns. **Please note that I'm not a substitute for professional medical advice. Always consult with your healthcare provider for medical concerns.** How can I help you today?",
          timestamp: new Date(),
        }
      ]);
    }
    
    // Financial advisor initial message
    if (financialChatHistory.length === 0) {
      setFinancialChatHistory([
        {
          role: 'assistant',
          content: "Welcome! I'm your Bibi financial advisor assistant. I can help you plan for the financial aspects of parenthood, including budgeting for baby expenses, understanding childcare costs, exploring tax benefits, and planning for your child's future education. **Note that I provide general financial information, not personalized financial advice.** How can I assist with your financial planning today?",
          timestamp: new Date(),
        }
      ]);
    }
  }, []);

  // Auto-scroll to the bottom of the chat
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    // Add user message to chat history
    const userMessage = {
      role: 'user',
      content: message,
      timestamp: new Date(),
    };
    
    setChatHistory(prev => [...prev, userMessage]);
    setMessage('');

    try {
      // Format system prompt based on active tab
      let systemPrompt;
      let temperature = 0.7;  // Default temperature
      
      if (activeTab === 'product-expert') {
        systemPrompt = `
          You are a helpful baby product expert assistant for an app called Bibi. 
          Your name is Bibi Product Assistant.
          Be conversational, casual, and friendly - as if chatting with a friend.
          Keep responses SHORT (2-3 sentences max for each point).
          ASK FOLLOW-UP QUESTIONS to understand the user's specific situation before giving extensive advice.
          For example, if they ask about strollers, ask about their lifestyle, space constraints, or specific needs.
          Be consultative rather than just informative - help users make decisions based on their situation.
          If asked about specific products, briefly mention pros and cons, then ASK what features matter most to them.
          Use a warm, supportive tone that feels like a real conversation, not a lecture.
          Avoid overwhelming the user with information - focus on what's most relevant.
          End your responses with a specific question to keep the conversation going.
        `;
      } else if (activeTab === 'therapist') {
        systemPrompt = `
          You are a compassionate DBT therapist assistant for an app called Bibi.
          Your name is Bibi Therapy Assistant.
          Be conversational, warm, and empathetic - like talking to a supportive friend.
          Keep responses SHORT (3-4 sentences maximum per point).
          Use a friendly, casual tone that feels like a real conversation.
          ASK FOLLOW-UP QUESTIONS to understand what the user is feeling and experiencing before offering solutions.
          Be consultative - help users identify specific strategies based on their unique situation.
          When suggesting DBT techniques, briefly explain ONE simple technique they could try immediately.
          Include a brief disclaimer about not being a replacement for professional therapy, but keep it conversational.
          Focus on quick, practical advice the user can implement right away.
          End messages with a thoughtful question that encourages them to reflect or share more.
          Make the user feel heard and understood rather than lectured.
        `;
        temperature = 0.6;  // Slightly lower temperature for more focused responses
      } else if (activeTab === 'medical') {
        systemPrompt = `
          You are a medical information assistant for an app called Bibi.
          Your name is Bibi Medical Assistant.
          Be conversational, friendly, and reassuring - like a knowledgeable friend.
          Keep responses SHORT (2-3 sentences maximum per point).
          ASK FOLLOW-UP QUESTIONS to understand the user's specific situation before providing detailed information.
          Include a very brief disclaimer about consulting healthcare providers, but make it sound natural and conversational.
          Focus on the most relevant information rather than being comprehensive.
          If asked about symptoms or concerns, ask clarifying questions about duration, severity, and context.
          Be consultative - help users determine what information is most relevant to their situation.
          End your messages with a specific question that encourages the user to share more details or clarify their needs.
          Use a warm, reassuring tone while still being factual.
          Prioritize clarity and brevity over exhaustive information.
        `;
        temperature = 0.5;  // Lower temperature for more factual, less creative responses
      } else if (activeTab === 'financial') {
        systemPrompt = `
          You are a financial advisor assistant for an app called Bibi.
          Your name is Bibi Financial Assistant.
          Be conversational, friendly, and non-judgmental - like a financially savvy friend.
          Keep responses SHORT (2-3 sentences maximum per point).
          ASK FOLLOW-UP QUESTIONS to understand the user's specific financial situation before providing detailed guidance.
          For example, if they ask about saving for college, ask about their timeframe, other financial priorities, or current savings approach.
          Be consultative - help users think through financial decisions based on their unique circumstances.
          Briefly mention that you're providing general information rather than personalized financial advice, but keep it conversational.
          Focus on the most practical next steps rather than comprehensive planning.
          Provide 1-2 actionable suggestions that feel doable, not overwhelming.
          Use a supportive tone that acknowledges the financial challenges of parenthood.
          End your responses with a specific question to understand more about their situation or priorities.
        `;
        temperature = 0.6;  // Balanced between creative and factual
      }

      // Format previous messages for context
      const previousMessages = chatHistory.map(item => ({
        role: item.role,
        content: item.content
      }));

      // Send message to API with conversation history
      const response = await sendMessage(message, {
        systemPrompt: systemPrompt,
        temperature: temperature,
        maxTokens: 1000,
        previousMessages: previousMessages // Pass conversation history
      });

      // Add assistant's response to chat history
      setChatHistory(prev => [
        ...prev,
        {
          role: 'assistant',
          content: response,
          timestamp: new Date(),
        }
      ]);
    } catch (err) {
      console.error('Failed to get response:', err);
      setChatHistory(prev => [
        ...prev,
        {
          role: 'assistant',
          content: "I'm sorry, I'm having trouble connecting right now. Please try again in a moment.",
          timestamp: new Date(),
          error: true,
        }
      ]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSuggestedQuestion = (question) => {
    setMessage(question);
    setSuggestionsPopupVisible(false);
  };
  
  const showSuggestionsPopup = () => {
    setSuggestionsPopupVisible(true);
  };
  
  const hideSuggestionsPopup = () => {
    setSuggestionsPopupVisible(false);
  };
  
  // Get pastel colors for suggestion items
  const getPastelColor = (index) => {
    const colors = [
      '#f5f0ff', // lavender
      '#fff0f5', // light pink
      '#f0f8ff', // light blue
      '#f0fff4', // light mint
      '#fff9f0', // light peach
      '#f8f4e3', // light yellow
    ];
    return colors[index % colors.length];
  };
  
  // Safely get the current suggested questions
  const currentSuggestedQuestions = getQuestionsForTab();
  
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  
  // Get the appropriate suggested questions based on active tab
  const getSuggestedQuestions = () => {
    switch(activeTab) {
      case 'product-expert':
        return productExpertQuestions;
      case 'therapist':
        return therapistQuestions;
      case 'medical':
        return medicalQuestions;
      case 'financial':
        return financialQuestions;
      default:
        return productExpertQuestions;
    }
  };
  
  const suggestedQuestions = getSuggestedQuestions();

  return (
    <div className={`chat-container ${
      activeTab === 'product-expert' 
        ? 'product-expert-chat' 
        : activeTab === 'therapist' 
          ? 'therapist-chat' 
          : activeTab === 'medical'
            ? 'medical-chat'
            : 'financial-chat'
    }`}>
      <div className="chat-header">
        <Title level={2}>Chat with Bibi</Title>
      </div>
      
      <div className="chat-type-description">
        {activeTab === 'product-expert' ? (
          <div className="chat-type-info product-expert">
            
            {/* Suggested Questions for Product Expert */}
            {currentSuggestedQuestions.length > 0 && (
              <div className="suggested-questions-horizontal">
                <div className="suggested-questions-header" onClick={showSuggestionsPopup}>
                  <span>You can try asking me:</span>
                </div>
                <div className="horizontal-questions">
                  <div className="rotating-questions" onClick={showSuggestionsPopup}>
                    <div className="question-display">
                      {currentSuggestedQuestions.length > 0 && (
                        <div className={`single-question ${!questionVisible ? 'fade-out' : ''}`}>
                          {currentSuggestedQuestions[currentQuestion % currentSuggestedQuestions.length]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : activeTab === 'therapist' ? (
          <div className="chat-type-info therapist">
            
            {/* Suggested Questions for Therapist */}
            {currentSuggestedQuestions.length > 0 && (
              <div className="suggested-questions-horizontal">
                <div className="suggested-questions-header" onClick={showSuggestionsPopup}>
                  <span>You can try asking me:</span>
                </div>
                <div className="horizontal-questions">
                  <div className="rotating-questions" onClick={showSuggestionsPopup}>
                    <div className="question-display">
                      {currentSuggestedQuestions.length > 0 && (
                        <div className={`single-question ${!questionVisible ? 'fade-out' : ''}`}>
                          {currentSuggestedQuestions[currentQuestion % currentSuggestedQuestions.length]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : activeTab === 'medical' ? (
          <div className="chat-type-info medical">
            
            {/* Suggested Questions for Medical */}
            {currentSuggestedQuestions.length > 0 && (
              <div className="suggested-questions-horizontal">
                <div className="suggested-questions-header" onClick={showSuggestionsPopup}>
                  <span>You can try asking me:</span>
                </div>
                <div className="horizontal-questions">
                  <div className="rotating-questions" onClick={showSuggestionsPopup}>
                    <div className="question-display">
                      {currentSuggestedQuestions.length > 0 && (
                        <div className={`single-question ${!questionVisible ? 'fade-out' : ''}`}>
                          {currentSuggestedQuestions[currentQuestion % currentSuggestedQuestions.length]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="chat-type-info financial">
            
            {/* Suggested Questions for Financial */}
            {currentSuggestedQuestions.length > 0 && (
              <div className="suggested-questions-horizontal">
                <div className="suggested-questions-header" onClick={showSuggestionsPopup}>
                  <span>You can try asking me:</span>
                </div>
                <div className="horizontal-questions">
                  <div className="rotating-questions" onClick={showSuggestionsPopup}>
                    <div className="question-display">
                      {currentSuggestedQuestions.length > 0 && (
                        <div className={`single-question ${!questionVisible ? 'fade-out' : ''}`}>
                          {currentSuggestedQuestions[currentQuestion % currentSuggestedQuestions.length]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="chat-messages">
        <List
          itemLayout="horizontal"
          dataSource={chatHistory}
          renderItem={(item) => (
            <List.Item className={`message-item ${item.role}`}>
              <div className="message-content">
                <div className="message-avatar">
                  {item.role === 'assistant' ? (
                    <Avatar 
                      icon={
                        activeTab === 'product-expert' 
                          ? <ShoppingOutlined /> 
                          : activeTab === 'therapist' 
                            ? <HeartOutlined /> 
                            : activeTab === 'medical'
                              ? <MedicineBoxOutlined />
                              : <DollarOutlined />
                      } 
                      style={{ 
                        backgroundColor: 
                          activeTab === 'product-expert' 
                            ? '#6B4EFF' 
                            : activeTab === 'therapist'
                              ? '#ff69b4' 
                              : activeTab === 'medical'
                                ? '#ff7f50'
                                : '#52c41a'
                      }} 
                    />
                  ) : (
                    <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#1890ff' }} />
                  )}
                </div>
                <div className="message-bubble">
                  <div className="message-text">
                    {item.error ? (
                      <Text type="danger">{item.content}</Text>
                    ) : (
                      <div className="markdown-content">
                        <ReactMarkdown>{item.content}</ReactMarkdown>
                      </div>
                    )}
                  </div>
                  <div className="message-time">
                    {item.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
        <div ref={messagesEndRef} />

        {loading && (
          <div className="loading-indicator">
            <Spin size="small" />
            <Text type="secondary">Bibi is thinking...</Text>
          </div>
        )}
      </div>

      {/* Fixed bottom container for input and tabs */}
      <div className="chat-bottom-container">
        <div className="chat-input">
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder={
              activeTab === 'product-expert' 
                ? 'Ask about baby products...' 
                : activeTab === 'therapist'
                  ? 'Ask about emotional support...'
                  : activeTab === 'medical'
                    ? 'Ask health questions...'
                    : 'Ask about budgeting...'
            }
            autoSize={{ minRows: 1, maxRows: 4 }}
            disabled={loading}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleSendMessage}
            disabled={loading || !message.trim()}
            style={{ 
              backgroundColor: 
                activeTab === 'product-expert' 
                  ? '#6B4EFF' 
                  : activeTab === 'therapist' 
                    ? '#ff69b4' 
                    : '#ff7f50',
              borderColor: 
                activeTab === 'product-expert' 
                  ? '#6B4EFF' 
                  : activeTab === 'therapist' 
                    ? '#ff69b4' 
                    : '#ff7f50'
            }}
          />
        </div>
      </div>
      
      {/* Suggestions Popup Modal */}
      <Modal
        title={<div style={{ fontSize: '16px' }}>Questions you can ask</div>}
        open={suggestionsPopupVisible}
        onCancel={hideSuggestionsPopup}
        footer={null}
        width={450}
        centered
        bodyStyle={{ maxHeight: '500px', overflow: 'auto', padding: '16px' }}
        style={{ borderRadius: '12px', overflow: 'hidden' }}
      >
        <List
          itemLayout="vertical"
          dataSource={getQuestionsForTab()}
          renderItem={(question, index) => (
            <List.Item
              key={index}
              onClick={() => handleSuggestedQuestion(question)}
              style={{ 
                cursor: 'pointer', 
                padding: '12px 16px', 
                borderRadius: '8px', 
                marginBottom: '10px',
                border: '1px solid #f0f0f0',
                backgroundColor: getPastelColor(index)
              }}
              className="suggestion-popup-item"
            >
              <div style={{ fontSize: '14px' }}>{question}</div>
            </List.Item>
          )}
        />
      </Modal>

      <div className="chat-tabs-container">
        <Tabs 
          activeKey={activeTab} 
          onChange={handleTabChange}
          className="chat-tabs bottom-tabs"
          tabBarGutter={0}
          size="large"
          centered={true}
          animated={false} /* Disable animations which can cause rendering issues */
        >
          <TabPane 
            tab={
              <div className="tab-content">
                <ShoppingOutlined /> 
                <div>Products</div>
              </div>
            } 
            key="product-expert"
            style={{ opacity: 1 }}
          />
          <TabPane 
            tab={
              <div className="tab-content">
                <HeartOutlined /> 
                <div>Support</div>
              </div>
            } 
            key="therapist"
            style={{ opacity: 1 }}
          />
          <TabPane 
            tab={
              <div className="tab-content">
                <MedicineBoxOutlined /> 
                <div>Health</div>
              </div>
            } 
            key="medical"
            style={{ opacity: 1 }}
          />
          <TabPane 
            tab={
              <div className="tab-content">
                <DollarOutlined /> 
                <div>Budget</div>
              </div>
            } 
            key="financial"
            style={{ opacity: 1 }}
          />
        </Tabs>
      </div>
    </div>
  );
};

export default Chat;