import React, { useState } from 'react';
import { Typography, Tabs, Card, Space, Input, Radio, Button, List, Tag, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserOutlined, TeamOutlined, GlobalOutlined, SearchOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import '../../assets/css/components/explore.css';
import '../../assets/css/plans.css'; // Add plans css for baby-centric design
import PlanDetails from './PlanDetails';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;

// Sample data for the Explore page - Using IDs that match mockPlans in PlanDetails
const sampleLists = [
  {
    id: '123e4567-e89b-12d3-a456-426614174000',
    title: 'Get Ready for Baby',
    creator: 'Bibi Team',
    creatorType: 'official',
    categories: ['Shopping', 'Research', 'Appointments'],
    itemCount: 5,
    dateCreated: '2023-05-15',
    image: 'https://via.placeholder.com/100',
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174001',
    title: 'Research Strollers',
    creator: 'Sarah Johnson',
    creatorType: 'friend',
    categories: ['Research', 'Try'],
    itemCount: 3,
    dateCreated: '2023-06-02',
    image: 'https://via.placeholder.com/100',
  },
  {
    id: '3',
    title: 'Baby Registry Must-Haves',
    creator: 'Emma Wilson',
    creatorType: 'public',
    categories: ['Registry', 'Gear', 'Nursery'],
    itemCount: 48,
    dateCreated: '2023-04-28',
    image: 'https://via.placeholder.com/100',
  },
];

// We'll map product clicks to the first list for demo purposes
const sampleProducts = [
  {
    id: '123e4567-e89b-12d3-a456-426614174000', // Using the list ID for demo
    name: 'Dr. Brown\'s Natural Flow Bottles',
    brand: 'Dr. Brown\'s',
    category: 'Feeding',
    rating: 4.7,
    price: '$24.99',
    reviewCount: 1250,
    image: 'https://via.placeholder.com/100',
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174001', // Using the list ID for demo
    name: 'Halo Sleepsack Swaddle',
    brand: 'Halo',
    category: 'Sleeping',
    rating: 4.9,
    price: '$29.99',
    reviewCount: 3420,
    image: 'https://via.placeholder.com/100',
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174000', // Using the list ID for demo
    name: 'Pampers Swaddlers Diapers',
    brand: 'Pampers',
    category: 'Diapering',
    rating: 4.8,
    price: '$39.99',
    reviewCount: 8752,
    image: 'https://via.placeholder.com/100',
  },
];

const Explore = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [filterVisible, setFilterVisible] = useState(false);
  const [feedFilter, setFeedFilter] = useState('popular');
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [sourceFilter, setSourceFilter] = useState([]);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleSearch = (value) => {
    setSearchValue(value);
    // Here you would typically filter your data based on the search value
  };

  const toggleFilter = () => {
    setFilterVisible(!filterVisible);
  };

  const handleFeedFilterChange = (e) => {
    setFeedFilter(e.target.value);
  };

  const handleAddToPlan = (item) => {
    // Here you would typically handle adding the item to a plan
    console.log(`Adding ${item.title || item.name} to plan`);
    // In a real app, you would add the item to the plan via API/Redux
    // For now, just show a success message
    Modal.success({
      title: 'Added to Plan',
      content: `${item.title || item.name} has been added to your plan.`,
    });
  };

  const handleShowDetails = (item) => {
    setSelectedItemId(item.id);
    setDetailsModalVisible(true);
  };

  const handleCloseDetails = () => {
    setDetailsModalVisible(false);
    setSelectedItemId(null);
  };

  const renderListItem = (item) => (
    <Card 
      hoverable 
      className="explore-card"
      onClick={() => handleShowDetails(item)}
    >
      <div className="explore-card-content">
        <div className="explore-card-image">
          <img src={item.image} alt={item.title} />
        </div>
        <div className="explore-card-details">
          <Title level={5}>{item.title}</Title>
          <div className="explore-card-meta">
            <Text type="secondary">
              {item.creatorType === 'official' ? (
                <span className="creator-tag creator-official">
                  <TeamOutlined style={{ marginRight: '4px' }} />
                  Bibi Team
                </span>
              ) : item.creatorType === 'friend' ? (
                <span className="creator-tag creator-friend">
                  <UserOutlined style={{ marginRight: '4px' }} />
                  Friend
                </span>
              ) : (
                <span className="creator-tag creator-public">
                  <GlobalOutlined style={{ marginRight: '4px' }} />
                  Public
                </span>
              )}
            </Text>
            <Text type="secondary">{item.itemCount} items</Text>
          </div>
          <div className="explore-card-categories">
            {item.categories.map(cat => (
              <Tag key={cat}>{cat}</Tag>
            ))}
          </div>
        </div>
      </div>
      <div className="explore-card-actions">
        <Button 
          type="primary" 
          size="small" 
          onClick={(e) => {
            e.stopPropagation();
            handleAddToPlan(item);
          }}
          style={{ 
            background: '#5c9dff',
            borderColor: '#4b87e8',
            borderRadius: '15px',
            fontSize: '13px'
          }}
        >
          Add to Emma's Plan
        </Button>
      </div>
    </Card>
  );

  const renderProductItem = (item) => (
    <Card 
      hoverable 
      className="explore-card"
      onClick={() => handleShowDetails(item)}
    >
      <div className="explore-card-content">
        <div className="explore-card-image">
          <img src={item.image} alt={item.name} />
        </div>
        <div className="explore-card-details">
          <Title level={5}>{item.name}</Title>
          <div className="explore-card-meta">
            <Text type="secondary">{item.brand}</Text>
            <Text type="secondary">${item.price}</Text>
          </div>
          <div className="explore-card-categories">
            <Tag>{item.category}</Tag>
            <Tag color="gold">{item.rating} ★ ({item.reviewCount})</Tag>
          </div>
        </div>
      </div>
      <div className="explore-card-actions">
        <Button 
          type="primary" 
          size="small" 
          onClick={(e) => {
            e.stopPropagation();
            handleAddToPlan(item);
          }}
          style={{ 
            background: '#5c9dff',
            borderColor: '#4b87e8',
            borderRadius: '15px',
            fontSize: '13px'
          }}
        >
          Add to Emma's Plan
        </Button>
      </div>
    </Card>
  );

  // Mock data for baby info - in a real app this would come from user profile
  const babyInfo = {
    name: "Emma",
    dueDate: "June 15, 2025",
    weekCount: 24,
    photoUrl: "../../assets/images/Ultrasound2.png"
  };

  // Recommendations
  const recommendations = [
    "Childcare options",
    "Nursery setup",
    "Baby-proofing home",
    "Hospital bag essentials"
  ];

  return (
    <div className="explore-container">
      {/* Baby profile header */}
      <div className="baby-profile-header">
        <div className="baby-profile-info">
          <div className="baby-photo-container">
            <img src={require("../../assets/images/Ultrasound2.png")} alt="Baby scan" className="baby-photo" />
          </div>
          <div className="baby-details">
            <Title level={2} style={{ fontSize: '24px', margin: 0 }}>Emma's Ideas</Title>
            <div className="baby-stats">
              <span className="baby-stat-item">
                <span className="baby-stat-label">Due: </span>
                <span className="baby-stat-value">{babyInfo.dueDate}</span>
              </span>
              <span className="baby-stat-item">
                <span className="baby-stat-label">Week: </span>
                <span className="baby-stat-value">{babyInfo.weekCount}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="baby-journey-phase">
          <div className="journey-phase-badge">2nd Trimester</div>
        </div>
      </div>
      
      {/* Recommendations */}
      <div className="progress-overview">
        <Card className="progress-card" size="small" bordered={false}>
          <div style={{ marginBottom: '12px' }}>
            <div className="section-title-with-icon">
              <i className="section-icon">📋</i>
              <Title level={5} style={{ fontSize: '16px', margin: 0 }}>Recommendations</Title>
            </div>
          </div>
          
          <div className="current-needs-tags">
            {recommendations.map((item, index) => (
              <Tag 
                key={index} 
                color="#5c9dff"
                style={{ 
                  marginBottom: '8px', 
                  borderRadius: '12px', 
                  padding: '4px 12px',
                  fontSize: '12px',
                  color: '#fff',
                  border: 'none'
                }}
              >
                {item}
              </Tag>
            ))}
          </div>
        </Card>
      </div>

      <div className="explore-search-section">
        <div className="plan-title-section">
          <div className="section-title-with-icon">
            <i className="section-icon">🔍</i>
            <Title level={4} style={{ fontSize: '16px', margin: 0 }}>Find Products & Lists</Title>
          </div>
          <div className="search-filter-container">
            <Search
              placeholder="Search for lists, products, or categories"
              onSearch={handleSearch}
              enterButton={<SearchOutlined />}
              className="explore-search"
            />
            <Button 
              icon={<FilterOutlined />} 
              onClick={toggleFilter}
              className={filterVisible ? 'filter-active' : ''}
              style={{ marginLeft: '8px' }}
            />
          </div>
        </div>
      </div>
      
      {/* List Details Modal */}
      <Modal
        title={null}
        open={detailsModalVisible}
        onCancel={handleCloseDetails}
        footer={null}
        width="100%"
        style={{ top: 0, maxWidth: '100vw', margin: 0, padding: 0 }}
        bodyStyle={{ height: 'calc(100vh - 60px)', padding: 0, overflow: 'auto' }}
        maskClosable={true}
        destroyOnClose={true}
        closable={true}
      >
        {selectedItemId && <PlanDetails 
          listId={selectedItemId} 
          isInExploreModal={true} 
          onBackToExplore={handleCloseDetails} 
        />}
      </Modal>

      {filterVisible && (
        <div className="explore-filters">
          <div className="filter-section">
            <Title level={5}>Feed</Title>
            <Radio.Group value={feedFilter} onChange={handleFeedFilterChange}>
              <Radio.Button value="popular">Popular</Radio.Button>
              <Radio.Button value="newest">Newest</Radio.Button>
              <Radio.Button value="friends">Just Friends</Radio.Button>
            </Radio.Group>
          </div>
          
          <div className="filter-section">
            <Title level={5}>Categories</Title>
            <div className="filter-tags">
              {['Feeding', 'Sleeping', 'Diapering', 'Nursery', 'Gear', 'Health', 'Toys'].map(cat => (
                <Tag.CheckableTag
                  key={cat}
                  checked={categoryFilter.includes(cat)}
                  onChange={checked => {
                    const newFilters = checked 
                      ? [...categoryFilter, cat] 
                      : categoryFilter.filter(t => t !== cat);
                    setCategoryFilter(newFilters);
                  }}
                >
                  {cat}
                </Tag.CheckableTag>
              ))}
            </div>
          </div>
          
          <div className="filter-section">
            <Title level={5}>Shared By</Title>
            <div className="filter-tags">
              <Tag.CheckableTag
                checked={sourceFilter.includes('bibi')}
                onChange={checked => {
                  const newFilters = checked 
                    ? [...sourceFilter, 'bibi'] 
                    : sourceFilter.filter(t => t !== 'bibi');
                  setSourceFilter(newFilters);
                }}
              >
                <TeamOutlined /> Bibi Team
              </Tag.CheckableTag>
              <Tag.CheckableTag
                checked={sourceFilter.includes('friends')}
                onChange={checked => {
                  const newFilters = checked 
                    ? [...sourceFilter, 'friends'] 
                    : sourceFilter.filter(t => t !== 'friends');
                  setSourceFilter(newFilters);
                }}
              >
                <UserOutlined /> Friends
              </Tag.CheckableTag>
              <Tag.CheckableTag
                checked={sourceFilter.includes('public')}
                onChange={checked => {
                  const newFilters = checked 
                    ? [...sourceFilter, 'public'] 
                    : sourceFilter.filter(t => t !== 'public');
                  setSourceFilter(newFilters);
                }}
              >
                <GlobalOutlined /> Public
              </Tag.CheckableTag>
            </div>
          </div>
        </div>
      )}

      <Tabs defaultActiveKey="1" className="explore-tabs">
        <TabPane tab="Lists for Emma" key="1">
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 4,
            }}
            dataSource={sampleLists}
            renderItem={renderListItem}
          />
          <div className="add-custom-button">
            <Button 
              type="dashed" 
              icon={<PlusOutlined />}
              style={{ 
                borderColor: '#5c9dff',
                color: '#5c9dff'
              }}
            >
              Create Your Own List for Emma
            </Button>
          </div>
        </TabPane>
        <TabPane tab={`Products for Week ${babyInfo.weekCount}`} key="2">
          <div className="previously-viewed">
            <div className="section-title-with-icon">
              <i className="section-icon">👀</i>
              <Title level={4} style={{ fontSize: '16px', margin: 0 }}>Recently Viewed</Title>
            </div>
            <div className="horizontal-scroll">
              {sampleProducts.slice(0, 2).map(product => (
                <Card key={product.id} size="small" className="mini-product-card">
                  <img src={product.image} alt={product.name} />
                  <Text ellipsis>{product.name}</Text>
                </Card>
              ))}
            </div>
          </div>
          <div className="section-title-with-icon" style={{ marginTop: '24px' }}>
            <i className="section-icon">🛍️</i>
            <Title level={4} style={{ fontSize: '16px', margin: 0 }}>Second Trimester Essentials</Title>
          </div>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 4,
            }}
            dataSource={sampleProducts}
            renderItem={renderProductItem}
          />
          <div className="add-custom-button">
            <Button 
              type="dashed" 
              icon={<PlusOutlined />}
              style={{ 
                borderColor: '#5c9dff',
                color: '#5c9dff'
              }}
            >
              Add a Different Product for Emma
            </Button>
          </div>
        </TabPane>
      </Tabs>
      
      {/* Add smaller space at the bottom to prevent cut-off */}
      <div style={{ height: '80px' }}></div>
    </div>
  );
};

export default Explore;