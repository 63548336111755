import React, { useState, useEffect } from 'react';
import { 
  useParams, 
  useHistory,
  useLocation
} from 'react-router-dom';
import { 
  Typography, 
  Button, 
  Card, 
  Space, 
  Tabs,
  Tag,
  Divider,
  Row,
  Col,
  List,
  Rate,
  Breadcrumb,
  message,
  Tooltip,
  Carousel,
  Image,
  Descriptions,
  Statistic,
  Empty
} from 'antd';
import { 
  ArrowLeftOutlined, 
  HomeOutlined,
  ShareAltOutlined,
  ShoppingCartOutlined,
  HeartOutlined,
  HeartFilled,
  StarOutlined,
  CommentOutlined,
  ShopOutlined,
  TagOutlined,
  SafetyCertificateOutlined,
  DollarOutlined
} from '@ant-design/icons';
import '../../assets/css/components/plans.css';
import ShareModal from '../components/modals/ShareModal';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const BrandModelDetails = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  
  // Extract plan ID, product ID, and brand/model ID from URL params
  const { planId, productId, brandModelId } = params;
  
  // Get data from location state or fetch it based on IDs
  const [plan, setPlan] = useState(location.state?.planData || null);
  const [product, setProduct] = useState(location.state?.productData || null);
  const [brandModel, setBrandModel] = useState(location.state?.brandModelData || {
    id: '',
    name: 'Loading...',
    brand: '',
    model: '',
    category: '',
    price: {
      range: 'N/A',
      msrp: 0,
      lowestPrice: 0
    },
    rating: 0,
    reviewCount: 0,
    description: 'Loading product information...',
    features: [],
    images: [],
    specifications: {},
    safetyInfo: [],
    whereAvailable: [],
    reviews: []
  });
  const [isFavorite, setIsFavorite] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  
  // If we don't have the data from location state, fetch it
  useEffect(() => {
    if (!brandModel.id) {
      // In a real app, this would be an API call
      // For this demo, we'll use mock data
      
      const mockBrandModels = {
        'bm-123': {
          id: 'bm-123',
          name: 'Babyletto Hudson 3-in-1 Convertible Crib',
          brand: 'Babyletto',
          model: 'Hudson 3-in-1',
          category: 'Nursery Furniture',
          price: {
            range: '$379-$399',
            msrp: 399.99,
            lowestPrice: 379.99
          },
          rating: 4.7,
          reviewCount: 2548,
          description: 'The Babyletto Hudson 3-in-1 Convertible Crib is a stylish and versatile piece of nursery furniture, featuring mid-century modern design with rounded spindles, tapered legs, and an open silhouette. Made from sustainable New Zealand pine wood, this eco-friendly crib is both GREENGUARD Gold Certified and finished with non-toxic, lead and phthalate safe paint, ensuring a healthy sleeping environment for your baby.',
          features: [
            'Converts to toddler bed and daybed (toddler rail included)',
            'Four adjustable mattress positions',
            'Made with sustainable New Zealand pine wood',
            'GREENGUARD Gold Certified',
            'Low VOC finishes and lead/phthalate safe',
            'Fixed sides for stability',
            'Standard size mattress compatibility',
            'Meets ASTM international and U.S. CPSC safety standards'
          ],
          images: [
            'https://m.media-amazon.com/images/I/71KbZBlQ2aL._SL1500_.jpg',
            'https://m.media-amazon.com/images/I/71Oe6yChIAL._SL1500_.jpg',
            'https://m.media-amazon.com/images/I/71KUeztFpYL._SL1500_.jpg',
            'https://m.media-amazon.com/images/I/71nKflLnTvL._SL1500_.jpg'
          ],
          specifications: {
            dimensions: '53.5"L x 29.8"W x 35"H',
            weight: '53.6 pounds',
            material: 'New Zealand pine wood',
            recommendedAge: '0-3 years',
            maxWeight: '50 pounds',
            warranty: '1 year limited manufacturer warranty'
          },
          safetyInfo: [
            'GREENGUARD Gold Certified – screened for 360+ VOCs and 10,000+ chemicals',
            'Meets ASTM international and US CPSC safety standards',
            'Non-toxic finish, lead and phthalate safe',
            'Fixed side rails for added safety (no drop-sides)'
          ],
          whereAvailable: [
            'Babylist',
            'Amazon',
            'Buy Buy Baby',
            'Wayfair',
            'Target',
            'Pottery Barn Kids'
          ],
          relatedProductIds: ['prod-456', 'prod-789', 'prod-101'],
          compareWithIds: ['bm-456', 'bm-789', 'bm-101'],
          reviews: [
            {
              userId: 'user1',
              userName: 'Sarah M.',
              rating: 5,
              title: 'Beautiful and practical',
              comment: 'Love this crib! It was easy to put together and looks beautiful in our nursery. The mid-century modern design is elegant and fits perfectly with our decor.',
              date: '2023-06-15',
              verifiedPurchase: true
            },
            {
              userId: 'user2',
              userName: 'Michael J.',
              rating: 4,
              title: 'Great quality, slightly difficult assembly',
              comment: 'The crib is sturdy and well-made. Assembly took longer than expected, but the end result is worth it. My daughter loves her new crib!',
              date: '2023-05-22',
              verifiedPurchase: true
            },
            {
              userId: 'user3',
              userName: 'Jessica T.',
              rating: 5,
              title: 'Perfect convertible option',
              comment: 'I love that this comes with the toddler rail included. Makes the price point even more reasonable. Very sturdy construction and beautiful design.',
              date: '2023-04-10',
              verifiedPurchase: true
            }
          ]
        },
        'bm-456': {
          id: 'bm-456',
          name: 'IKEA Sniglar Crib',
          brand: 'IKEA',
          model: 'Sniglar',
          category: 'Nursery Furniture',
          price: {
            range: '$89-$99',
            msrp: 99.99,
            lowestPrice: 89.99
          },
          rating: 4.2,
          reviewCount: 1325,
          description: 'The IKEA Sniglar Crib is a simple, sturdy, and affordable option for your nursery. Made from solid beech wood, this minimalist crib features a classic design that fits with any decor style.',
          features: [
            'Made from solid beech wood',
            'Mattress base can be placed at two different heights',
            'Simple assembly',
            'Meets ASTM international and U.S. CPSC safety standards',
            'Budget-friendly option'
          ],
          images: [
            'https://www.ikea.com/us/en/images/products/sniglar-crib-beech__0419592_pe576389_s5.jpg',
            'https://www.ikea.com/us/en/images/products/sniglar-crib-beech__0654889_pe608872_s5.jpg',
            'https://www.ikea.com/us/en/images/products/sniglar-crib-beech__0814727_pe772618_s5.jpg'
          ],
          specifications: {
            dimensions: '53.5"L x 29.5"W x 33.5"H',
            weight: '40 pounds',
            material: 'Solid beech wood',
            recommendedAge: '0-2 years',
            maxWeight: '50 pounds',
            warranty: '90-day limited warranty'
          },
          safetyInfo: [
            'Meets ASTM international and US CPSC safety standards',
            'Fixed side rails (no drop-sides)',
            'Non-toxic unfinished wood'
          ],
          whereAvailable: [
            'IKEA',
            'Amazon (third-party sellers)'
          ]
        }
      };
      
      // Find the specific brand/model
      const foundBrandModel = mockBrandModels[brandModelId];
      
      if (foundBrandModel) {
        setBrandModel(foundBrandModel);
      } else {
        message.error('Brand/model not found');
        // Navigate back to product page
        history.push(`/plan/${planId}/product/${productId}`);
      }
      
      // If we don't have product info, we'll also need to fetch that
      if (!product) {
        // In a real app, we'd fetch the product details here
        // For this demo, navigate back
        history.push(`/plan/${planId}/product/${productId}`);
      }
    }
  }, [brandModelId, planId, productId, history, product, brandModel, location.state]);
  
  // Go back to the product details page
  const handleGoBack = () => {
    history.push(`/plan/${planId}/product/${productId}`);
  };
  
  // Add to favorites
  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite);
    message.success(isFavorite ? 'Removed from favorites' : 'Added to favorites');
  };
  
  // Share this brand/model
  const handleShare = () => {
    setShareModalVisible(true);
  };
  
  // Add to cart/shopping list (this would be implemented in a real app)
  const handleAddToCart = () => {
    message.success(`Added ${brandModel.name} to your shopping list`);
  };
  
  // Render stars for ratings
  const renderRatingStars = (rating) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Rate disabled defaultValue={rating} allowHalf />
        <Text style={{ marginLeft: '8px' }}>({rating}/5)</Text>
      </div>
    );
  };
  
  // Don't render if we don't have the data yet
  if (!brandModel) {
    return <div>Loading...</div>;
  }
  
  return (
    <div className="brand-model-details-container">
      <div className="brand-model-header" style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Breadcrumb style={{ fontSize: '14px' }}>
            <Breadcrumb.Item href="/plan">
              <HomeOutlined /> Plans
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/plan/${planId}`}>
              <span>{plan ? plan.name : 'Plan'}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/plan/${planId}/product/${productId}`}>
              <span>{product ? product.name : 'Product'}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{brandModel.name}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          
          <Button icon={<ArrowLeftOutlined />} onClick={handleGoBack}>
            Back to Product
          </Button>
        </div>
      </div>
      
      <Card className="brand-model-content-card">
        <div className="brand-model-header-section">
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <div className="brand-model-image-container" style={{ marginBottom: '24px' }}>
                <Carousel autoplay>
                  {brandModel.images && brandModel.images.length > 0 ? (
                    brandModel.images.map((image, index) => (
                      <div key={index}>
                        <Image 
                          src={image} 
                          alt={`${brandModel.name} - Image ${index+1}`} 
                          style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }} 
                        />
                      </div>
                    ))
                  ) : (
                    <div>
                      <div style={{ 
                        height: '300px', 
                        background: '#f0f2f5', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center' 
                      }}>
                        <Text type="secondary">No images available</Text>
                      </div>
                    </div>
                  )}
                </Carousel>
              </div>
              
              <div className="brand-model-action-buttons" style={{ display: 'flex', gap: '16px', marginBottom: '24px' }}>
                <Button 
                  type="primary" 
                  icon={<ShoppingCartOutlined />} 
                  size="large" 
                  onClick={handleAddToCart}
                  style={{ flex: 1 }}
                >
                  Add to Shopping List
                </Button>
                <Button 
                  icon={isFavorite ? <HeartFilled style={{ color: '#ff4d4f' }} /> : <HeartOutlined />} 
                  size="large" 
                  onClick={handleToggleFavorite}
                >
                  {isFavorite ? 'Favorited' : 'Favorite'}
                </Button>
                <Button 
                  icon={<ShareAltOutlined />} 
                  size="large" 
                  onClick={handleShare}
                >
                  Share
                </Button>
              </div>
              
              <div className="brand-model-availability" style={{ marginBottom: '24px' }}>
                <Title level={5}>Where to Buy</Title>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {brandModel.whereAvailable && brandModel.whereAvailable.length > 0 ? (
                    brandModel.whereAvailable.map((store, index) => (
                      <Tag 
                        key={index} 
                        color="blue" 
                        icon={<ShopOutlined />}
                        style={{ padding: '4px 8px', fontSize: '14px' }}
                      >
                        {store}
                      </Tag>
                    ))
                  ) : (
                    <Text type="secondary">No retailer information available</Text>
                  )}
                </div>
              </div>
            </Col>
            
            <Col xs={24} md={12}>
              <div className="brand-model-info">
                <Title level={2}>{brandModel.name}</Title>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                  <Tag color="purple" style={{ marginRight: '12px', padding: '2px 8px' }}>
                    {brandModel.brand}
                  </Tag>
                  <Tag color="cyan" style={{ marginRight: '12px', padding: '2px 8px' }}>
                    {brandModel.category}
                  </Tag>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Rate disabled defaultValue={brandModel.rating} allowHalf style={{ fontSize: '14px' }} />
                    <Text type="secondary" style={{ marginLeft: '8px' }}>
                      ({brandModel.reviewCount} reviews)
                    </Text>
                  </div>
                </div>
                
                <div className="price-container" style={{ 
                  background: '#f0f7ff', 
                  padding: '12px 16px', 
                  borderRadius: '8px', 
                  marginBottom: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <DollarOutlined style={{ fontSize: '18px', marginRight: '8px', color: '#5c9dff' }} />
                  <div>
                    <Title level={3} style={{ margin: 0, color: '#5c9dff' }}>{brandModel.price.range}</Title>
                    <Text type="secondary">Price range across retailers</Text>
                  </div>
                </div>
                
                <Paragraph style={{ fontSize: '16px' }}>
                  {brandModel.description}
                </Paragraph>
                
                <div className="key-features" style={{ marginTop: '16px', marginBottom: '24px' }}>
                  <Title level={4}>Key Features</Title>
                  {brandModel.features && brandModel.features.length > 0 ? (
                    <ul style={{ paddingLeft: '20px' }}>
                      {brandModel.features.map((feature, index) => (
                        <li key={index} style={{ marginBottom: '8px' }}>{feature}</li>
                      ))}
                    </ul>
                  ) : (
                    <Text type="secondary">No feature information available</Text>
                  )}
                </div>
                
                <div className="safety-features" style={{ marginBottom: '24px' }}>
                  <Title level={4}>Safety Information</Title>
                  {brandModel.safetyInfo && brandModel.safetyInfo.length > 0 ? (
                    <ul style={{ paddingLeft: '20px', color: '#52c41a' }}>
                      {brandModel.safetyInfo.map((info, index) => (
                        <li key={index} style={{ marginBottom: '8px' }}>
                          <SafetyCertificateOutlined style={{ marginRight: '8px', color: '#52c41a' }} />
                          {info}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Text type="secondary">No safety information available</Text>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        
        <Divider />
        
        <div className="brand-model-details-tabs">
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Specifications" key="specs">
              <div className="specifications-section" style={{ marginTop: '24px' }}>
                <Title level={4}>Product Specifications</Title>
                {brandModel.specifications ? (
                  <Descriptions bordered column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
                    {Object.entries(brandModel.specifications || {}).map(([key, value]) => (
                      <Descriptions.Item 
                        key={key} 
                        label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {value}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                ) : (
                  <Text type="secondary">No specifications available</Text>
                )}
              </div>
            </TabPane>
            
            <TabPane tab="Reviews" key="reviews">
              <div className="reviews-section" style={{ marginTop: '24px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                  <Title level={4}>Customer Reviews</Title>
                  <div className="rating-summary">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Title level={3} style={{ margin: 0, marginRight: '8px' }}>{brandModel.rating}</Title>
                      <div>
                        <Rate disabled defaultValue={brandModel.rating} allowHalf />
                        <div>
                          <Text type="secondary">{brandModel.reviewCount} reviews</Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {brandModel.reviews && brandModel.reviews.length > 0 ? (
                  <List
                    itemLayout="vertical"
                    dataSource={brandModel.reviews}
                    renderItem={review => (
                      <List.Item key={review.userId}>
                        <div style={{ 
                          border: '1px solid #f0f0f0', 
                          borderRadius: '8px', 
                          padding: '16px',
                          background: review.rating >= 4 ? '#f6ffed' : '#fff7e6'
                        }}>
                          <div style={{ marginBottom: '8px' }}>
                            <Text strong style={{ fontSize: '16px' }}>{review.title}</Text>
                            <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0' }}>
                              <Rate disabled defaultValue={review.rating} style={{ fontSize: '14px' }} />
                              <Text type="secondary" style={{ marginLeft: '8px', fontSize: '12px' }}>
                                by {review.userName} • {review.date}
                              </Text>
                              {review.verifiedPurchase && (
                                <Tag color="green" style={{ marginLeft: '8px', fontSize: '12px' }}>
                                  Verified Purchase
                                </Tag>
                              )}
                            </div>
                          </div>
                          <Paragraph>{review.comment}</Paragraph>
                        </div>
                      </List.Item>
                    )}
                  />
                ) : (
                  <Empty description="No reviews available yet" />
                )}
              </div>
            </TabPane>
            
            <TabPane tab="Compare" key="compare">
              <div className="compare-section" style={{ marginTop: '24px' }}>
                <Title level={4}>Compare with Similar Products</Title>
                <Paragraph>
                  This feature would display a comparison table with similar products in a real app.
                </Paragraph>
                <div style={{ background: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
                  <Title level={5}>Popular Comparisons</Title>
                  <ul>
                    <li>Babyletto Hudson vs. Pottery Barn Kendall</li>
                    <li>Babyletto Hudson vs. DaVinci Jenny Lind</li>
                    <li>Babyletto Hudson vs. IKEA Sniglar</li>
                  </ul>
                </div>
              </div>
            </TabPane>
            
            <TabPane tab="Q&A" key="qa">
              <div className="qa-section" style={{ marginTop: '24px' }}>
                <Title level={4}>Questions & Answers</Title>
                <Paragraph>
                  This section would display questions and answers from customers in a real app.
                </Paragraph>
                <Button icon={<CommentOutlined />} type="primary">
                  Ask a Question
                </Button>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Card>
      
      {/* Share Modal */}
      <ShareModal
        visible={shareModalVisible}
        onCancel={() => setShareModalVisible(false)}
        itemType="brand/model"
        itemName={brandModel.name}
        itemId={brandModel.id}
      />
      
      {/* Add space at the bottom to prevent content being hidden by the navigation menu */}
      <div style={{ height: '120px' }}></div>
    </div>
  );
};

export default BrandModelDetails;