const initialState = {
  // User goals
  userGoals: [],
  userGoalsLoading: false,
  userGoalsError: null,
  
  // Suggested goals
  suggestedGoals: [],
  suggestedGoalsLoading: false,
  suggestedGoalsError: null,
  
  // Current goal details
  currentGoal: null,
  currentGoalLoading: false,
  currentGoalError: null,
  
  // Progress stats
  progress: {
    total_items: 0,
    completed_items: 0,
    in_progress_items: 0,
    not_started_items: 0,
    completion_percentage: 0
  },
  
  stats: {
    completed_goals: 0,
    active_goals: 0
  },
  
  // Create goal
  createGoalLoading: false,
  createGoalError: null,
  
  // Update goal
  updateGoalLoading: false,
  updateGoalError: null,
  
  // Delete goal
  deleteGoalLoading: false,
  deleteGoalError: null,
  
  // Accept/reject goal
  acceptGoalLoading: false,
  acceptGoalError: null,
  
  // Goal items
  createGoalItemLoading: false,
  createGoalItemError: null,
  
  addItemsToGoalLoading: false,
  addItemsToGoalError: null,
  
  updateGoalItemLoading: false,
  updateGoalItemError: null,
  
  deleteGoalItemLoading: false,
  deleteGoalItemError: null,
  
  updateGoalItemStatusLoading: false,
  updateGoalItemStatusError: null
};

export default initialState;