import React, { useState } from "react";
import { connect } from "react-redux";
import { createSelector } from 'reselect';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
  Link
} from "react-router-dom";
import { Layout } from "antd";
import SideMenu from "../components/navigation/SideMenu";
import WelcomeChatbot from "../components/Home/Components/WelcomeChatbot";
import MainNavigation from "../components/navigation/MainNavigation";
import Onboarding from "../components/onboarding/Onboarding";
import NewCategories from "../components/checklist/NewCategories";
import Friends from "../views/Friends";
import Notepad from "../components/notes/NotePad";
import Dashboard from "../views/Dashboard";
import Plans from "../views/Plans";
import PlanDetails from "../views/PlanDetails";
import ProductDetails from "../views/ProductDetails";
import BrandModelDetails from "../views/BrandModelDetails";
import Explore from "../views/Explore";
import Chat from "../views/Chat";
import Profile from "../views/Profile";
import ListCreationScreen from "../mockup/ListCreationScreen";
import ListBuildingScreen from "../mockup/ListBuildingScreen";
import ItemDetailScreen from "../mockup/ItemDetailScreen";
import AddEditItemScreen from "../mockup/AddEditItemScreen";
import ChecklistScreen from "../mockup/ChecklistScreen";
import FilterScreen from "../mockup/FilterScreen";
import SharingList from "../mockup/SharingList";
import SharedListViewScreen from "../mockup/SharedListViewScreen";
import CommunityScreen from "../mockup/CommunityScreen";
import SettingsScreen from "../mockup/SettingsScreen";
import { setUserType } from "../redux/users/actions";
import "../../assets/css/components/button.css";
import "../../assets/css/components/header.css";
import Logo from "../../assets/images/logo";

const { Header, Sider, Content } = Layout;

export const userTypes = {
  personal: { name: "Personal", color: "#FFB3BA" },
  expert: { name: "Expert", color: "#FFFFBA" }
};

const AppRouter = (props) => {
  const [collapsed] = useState(true);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);  

  return (
    <Router>
      <Layout>
        {/* Header is commented out as requested
        <Header className="headerContainer">
          <div className="headerContent" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', padding: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <Link className="headerLogo" to="/home">
                <Logo/>
              </Link>
            </div>
          </div>
          <WelcomeChatbot 
            isOpen={isChatbotOpen}
            onClose={() => setIsChatbotOpen(false)}
          />
        </Header>
        */}
        <Layout>
          <Content className="mainContent">
            <Switch>
              <Route exact path="/">
                <Redirect to="/plan" />
              </Route>
              <Route exact path="/login">
                {props.user ? <Redirect to="/plan" /> : <div>Login component here</div>}
              </Route>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/create" component={ListCreationScreen} />
              <Route path="/community" component={CommunityScreen} />
              <Route path="/explore" component={Explore} />
              <Route path="/chat" component={Chat} />
              <Route path="/profile" component={Profile} />
              <Route path="/settings" component={SettingsScreen} />
              <Route path="/list/:listId/item/:itemId" component={ItemDetailScreen} />
              <Route path="/list/:listId/edit-item/:itemId" component={AddEditItemScreen} />
              <Route path="/list/:listId/add-item" component={AddEditItemScreen} />
              <Route path="/list/:listId/checklist" component={ChecklistScreen} />
              <Route path="/list/:listId/filter" component={FilterScreen} />
              <Route path="/list/:listId/share" component={SharingList} />
              <Route path="/shared-list/:sharedListId" component={SharedListViewScreen} />
              <Route path="/list/:listId" component={ListBuildingScreen} />
              <Route exact path="/Notepad" component={Notepad} />
              <Route exact path="/onboarding" component={Onboarding} />
              <Route exact path="/categories" component={NewCategories} />
              <Route exact path="/friends" component={Friends} />
              <Route path="/plan/:listId/product/:productId/brand/:brandModelId" component={BrandModelDetails} />
              <Route path="/plan/:listId/product/:productId" component={ProductDetails} />
              <Route path="/plan/:listId" component={PlanDetails} />
              <Route exact path="/plan" component={Plans} />
              <Route exact path="/home" component={Plans} />
              <Route path="*" component={() => <div>Page not found</div>} />
            </Switch>
          </Content>
          <Sider
            collapsed={collapsed}
            collapsedWidth={0}
            className="sider"
          >
            <SideMenu user={props.user} />
          </Sider>
        </Layout>
        
        {/* Bottom Navigation */}
        <MainNavigation />
      </Layout>
    </Router>
  );
};

const getUserState = state => state.auth.user;
const getIsSuperuserState = state => state.users.isSuperuser;
const getCurrentUserTypeState = state => state.users.currentUserType;

const getAppRouterProps = createSelector(
  [getUserState, getIsSuperuserState, getCurrentUserTypeState],
  (user, isSuperuser, currentUserType) => ({
    user,
    isSuperuser,
    currentUserType
  })
);

const mapStateToProps = (state) => {
  const props = getAppRouterProps(state);
  return props;
};

const mapDispatchToProps = {
  setUserType,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppRouter));