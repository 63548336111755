import React, { useState } from 'react';
import { 
  Typography, 
  Card, 
  Avatar, 
  Button, 
  Tabs, 
  List, 
  Space, 
  Tag, 
  Statistic, 
  Row, 
  Col,
  Switch,
  Form,
  Input,
  DatePicker,
  Upload,
  Progress
} from 'antd';
import dayjs from 'dayjs';
import { 
  SettingOutlined, 
  UserOutlined, 
  EditOutlined, 
  CameraOutlined,
  LockOutlined,
  UnlockOutlined,
  TeamOutlined,
  TrophyOutlined,
  FileTextOutlined,
  StarOutlined,
  UploadOutlined,
  PlusOutlined,
  RightCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import '../../assets/css/components/profile.css';
import '../../assets/css/plans.css'; // Add plans css for baby-centric design

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

// Sample user data
const sampleUser = {
  name: 'Sarah Johnson',
  email: 'sarah.johnson@example.com',
  photo: null,
  dueDate: '2023-12-15',
  weekCount: 24,
  announced: false,
  children: [
    { name: 'Emma', birthday: '2020-05-22' }
  ],
  about: 'First-time mom expecting baby #2. Looking for advice on managing two under three!',
  friends: 12,
  following: 8,
  followers: 5,
  goalStats: {
    completed: 3,
    inProgress: 5,
    itemsCompleted: 42,
    openItems: 18,
    completionPercentage: 70
  },
  listStats: {
    created: 4,
    shared: 7,
    thanksReceived: 15
  }
};

// Sample lists
const sampleLists = [
  {
    id: '1',
    title: 'Newborn Essentials',
    modules: ['Feeding', 'Sleeping', 'Diapering'],
    itemCount: 35,
    completedItems: 20,
    completionPercentage: 57,
    dateCreated: '2023-05-15',
  },
  {
    id: '2',
    title: 'Hospital Bag Checklist',
    modules: ['Hospital', 'Labor', 'Postpartum'],
    itemCount: 22,
    completedItems: 18,
    completionPercentage: 82,
    dateCreated: '2023-06-02',
  }
];

// Sample saved lists
const sampleSavedLists = [
  {
    id: '3',
    title: 'Baby Registry Must-Haves',
    creator: 'Emma Wilson',
    creatorType: 'friend',
    creatorAvatar: null,
    modules: ['Registry', 'Gear', 'Nursery'],
    itemCount: 48,
    dateCreated: '2023-04-28',
  },
  {
    id: '4',
    title: 'Second Trimester Guide',
    creator: 'Bibi Team',
    creatorType: 'official',
    creatorAvatar: null,
    modules: ['Health', 'Wellness', 'Shopping'],
    itemCount: 32,
    dateCreated: '2023-05-18',
  }
];

// Sample people
const samplePeople = [
  {
    id: '1',
    name: 'Emma Wilson',
    photo: null,
    expertise: 'Newborn Expert',
  },
  {
    id: '2',
    name: 'Michael Chen',
    photo: null,
    expertise: 'Baby Sleep Consultant',
  }
];

// Sample completed goals
const sampleCompletedGoals = [
  {
    id: '1',
    title: 'First Trimester Checklist',
    completedDate: '2023-03-15',
    itemsCompleted: 12,
  },
  {
    id: '2',
    title: 'Hospital Bag',
    completedDate: '2023-04-22',
    itemsCompleted: 18,
  },
  {
    id: '3',
    title: 'Nursery Setup',
    completedDate: '2023-05-10',
    itemsCompleted: 15,
  }
];

// Sample growth milestones
const sampleGrowthMilestones = [
  {
    id: '1',
    title: 'First Ultrasound',
    date: 'Week 12 • Apr 2',
    note: 'First time seeing our baby! ❤️',
    status: 'completed'
  },
  {
    id: '2',
    title: 'Felt First Kicks',
    date: 'Week 22 • May 5',
    note: 'Amazing feeling! Baby was very active after dinner.',
    status: 'current'
  },
  {
    id: '3',
    title: 'Anatomy Scan',
    date: 'Week 26 • May 28',
    note: 'Looking forward to seeing baby\'s development!',
    status: 'upcoming'
  }
];

const Profile = () => {
  const [editMode, setEditMode] = useState(false);
  const [user, setUser] = useState(sampleUser);
  const [form] = Form.useForm();

  const handleToggleAnnounced = (checked) => {
    setUser({
      ...user,
      announced: checked
    });
  };

  const handleEditProfile = () => {
    setEditMode(true);
    form.setFieldsValue({
      name: user.name,
      dueDate: user.dueDate ? dayjs(user.dueDate) : null,
      about: user.about,
      // Add other fields as needed
    });
  };

  const handleSaveProfile = () => {
    form.validateFields().then(values => {
      setUser({
        ...user,
        ...values,
        dueDate: values.dueDate ? values.dueDate.format('YYYY-MM-DD') : null,
      });
      setEditMode(false);
    });
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const renderProfileInfo = () => {
    if (editMode) {
      return (
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            name: user.name,
            about: user.about,
            // Add other fields as needed
          }}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input />
          </Form.Item>
          
          <Form.Item
            name="dueDate"
            label="Due Date"
          >
            <DatePicker />
          </Form.Item>
          
          <Form.Item
            name="about"
            label="About me"
          >
            <TextArea rows={4} />
          </Form.Item>
          
          <Form.Item>
            <Space>
              <Button 
                type="primary" 
                onClick={handleSaveProfile}
                style={{ 
                  background: '#5c9dff',
                  borderColor: '#4b87e8',
                  borderRadius: '18px',
                  fontWeight: 500
                }}
              >
                Save
              </Button>
              <Button 
                onClick={handleCancelEdit}
                style={{ 
                  borderRadius: '18px',
                  fontWeight: 500
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      );
    }
    
    return (
      <>
        <div className="baby-profile-header" style={{ position: 'relative' }}>
          <Button 
            type="link" 
            icon={<EditOutlined />} 
            onClick={handleEditProfile}
            className="edit-button"
            style={{ 
              color: '#5c9dff', 
              padding: '0 4px', 
              position: 'absolute', 
              top: '12px', 
              right: '12px',
              zIndex: 2
            }}
          >
            Edit
          </Button>
          <div className="baby-profile-info">
            <div className="baby-photo-container" style={{ width: '60px', height: '60px' }}>
              <Avatar 
                size={54} 
                icon={<UserOutlined />} 
                src={user.photo} 
                className="baby-photo" 
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              />
            </div>
            <div className="baby-details">
              <div className="profile-name-container">
                <Title level={2} style={{ fontSize: '24px', margin: 0 }}>{user.name}</Title>
              </div>
              <div className="baby-stats">
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
                  <span className="baby-stat-item">
                    <span className="baby-stat-label">Due: </span>
                    <span className="baby-stat-value">{user.dueDate}</span>
                  </span>
                  <span className="baby-stat-item">
                    <span className="baby-stat-label">Week: </span>
                    <span className="baby-stat-value">{user.weekCount}</span>
                  </span>
                  {user.children.length > 0 && (
                    <span className="baby-stat-item">
                      <span className="baby-stat-label">Child: </span>
                      <span className="baby-stat-value">
                        {user.children[0].name}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="baby-journey-phase">
            <div className="journey-phase-badge">2nd Trimester</div>
          </div>
        </div>
        
        <div className="profile-announced">
          <div className="announced-container">
            <div className="announced-label">
              <Text>Pregnancy Announced:</Text>
              {user.announced ? 
                <UnlockOutlined className="announced-icon public" /> : 
                <LockOutlined className="announced-icon private" />
              }
            </div>
            <Switch 
              checked={user.announced} 
              onChange={handleToggleAnnounced} 
              style={{ backgroundColor: user.announced ? '#5c9dff' : undefined }}
            />
          </div>
          <Text type="secondary" className="announced-description">
            {user.announced 
              ? "Your profile is visible to friends and in search." 
              : "Only you can see your profile. You're in private mode."}
          </Text>
        </div>
        
        <div className="profile-about">
          <div className="section-title-with-icon">
            <i className="section-icon">📝</i>
            <Title level={5} style={{ fontSize: '16px', margin: 0 }}>About me</Title>
          </div>
          <Paragraph>{user.about}</Paragraph>
        </div>
        
        <div className="profile-connections">
          <div className="connection-item">
            <div className="connection-count">{user.friends}</div>
            <div className="connection-label">Friends</div>
          </div>
          <div className="connection-item">
            <div className="connection-count">{user.following}</div>
            <div className="connection-label">Following</div>
          </div>
          <div className="connection-item">
            <div className="connection-count">{user.followers}</div>
            <div className="connection-label">Followers</div>
          </div>
        </div>
      </>
    );
  };

  const renderListItem = (item) => (
    <Card 
      hoverable 
      className="plan-card"
    >
      <div className="plan-content">
        <div className="plan-info">
          <Title level={4} style={{ margin: 0, marginBottom: '4px', fontSize: '16px' }}>{item.title}</Title>
          
          <div className="plan-meta">
            <div className="plan-modules">
              {item.modules.map((module, idx) => (
                <Tag key={idx} style={{ marginRight: 0, fontSize: '11px', padding: '0 6px' }}>
                  {module}
                </Tag>
              ))}
            </div>
            
            <div className="plan-stats-group">
              <div className="plan-progress-row">
                <Text type="secondary" className="plan-progress-text">
                  {item.completedItems || 0}/{item.itemCount} items
                </Text>
                <div className="plan-progress-mini">
                  <div 
                    className={`plan-progress-mini-bar ${
                      (item.completionPercentage || 0) > 66 ? 'plan-progress-high' : 
                      (item.completionPercentage || 0) > 33 ? 'plan-progress-medium' : 'plan-progress-low'
                    }`}
                    style={{
                      width: `${item.completionPercentage || 0}%`
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="plan-actions">
          <RightCircleOutlined style={{ fontSize: '18px', color: '#5c9dff' }} />
        </div>
      </div>
    </Card>
  );

  const renderSavedListItem = (item) => (
    <Card 
      hoverable 
      className="plan-card"
    >
      <div className="plan-content">
        <div className="plan-info">
          <Title level={4} style={{ margin: 0, marginBottom: '4px', fontSize: '16px' }}>{item.title}</Title>
          
          <div className="plan-meta">
            <Text type="secondary">
              {item.creatorType === 'official' ? (
                <span className="creator-tag creator-official">
                  <TeamOutlined style={{ marginRight: '4px' }} />
                  Bibi Team
                </span>
              ) : item.creatorType === 'friend' ? (
                <span className="creator-tag creator-friend">
                  <UserOutlined style={{ marginRight: '4px' }} />
                  Friend
                </span>
              ) : (
                <span className="creator-tag creator-public">
                  <UserOutlined style={{ marginRight: '4px' }} />
                  Public
                </span>
              )}
            </Text>
            
            <div className="plan-modules">
              {item.modules.map((module, idx) => (
                <Tag key={idx} style={{ marginRight: 0, fontSize: '11px', padding: '0 6px' }}>
                  {module}
                </Tag>
              ))}
            </div>
          </div>
        </div>
        
        <div className="plan-actions">
          <RightCircleOutlined style={{ fontSize: '18px', color: '#5c9dff' }} />
        </div>
      </div>
    </Card>
  );

  return (
    <div className="profile-container">
      <div className="profile-section">
        <Card className="profile-info-card">
          {renderProfileInfo()}
          
          <div className="profile-actions">
            <Button 
              icon={<TeamOutlined />} 
              style={{ 
                borderRadius: '18px',
                color: '#5c9dff',
                borderColor: '#d0dcff'
              }}
            >
              Find Friends
            </Button>
            <Button 
              icon={<SettingOutlined />}
              style={{ 
                borderRadius: '18px',
                color: '#5c9dff',
                borderColor: '#d0dcff'
              }}
            >
              Settings
            </Button>
          </div>
        </Card>
      </div>
      
      <div className="progress-overview">
        <Card className="progress-card" size="small" bordered={false}>
          <div className="journey-context">
            <div className="next-milestone">
              <div className="milestone-label">Next milestone:</div>
              <div className="milestone-value">Glucose Test <span className="milestone-when">(Week 26)</span></div>
            </div>
            <div className="upcoming-appointment">
              <div className="appointment-label">Upcoming:</div>
              <div className="appointment-value">OB-GYN Visit <span className="appointment-when">(May 15)</span></div>
            </div>
          </div>
          
          <Row gutter={[16, 16]} align="middle" style={{ marginTop: '12px' }}>
            <Col xs={24} sm={10} md={12} lg={14}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                <Title level={5} style={{ margin: 0, marginRight: 10, fontSize: '16px' }}>Prep Progress: </Title>
                <Progress 
                  percent={user.goalStats.completionPercentage}
                  status="active"
                  strokeColor={{
                    '0%': '#5c9dff',
                    '100%': '#4b87e8',
                  }}
                  style={{ 
                    flex: 1, 
                    marginBottom: 0 
                  }}
                  trailColor="#edf2ff"
                />
              </div>
            </Col>
            <Col xs={8} sm={4} md={4} lg={3}>
              <div className="stat-compact">
                <CheckCircleOutlined style={{ color: '#5c9dff', marginRight: 5 }} />
                <span>{user.goalStats.completed} Done</span>
              </div>
            </Col>
            <Col xs={8} sm={5} md={4} lg={3}>
              <div className="stat-compact">
                <ClockCircleOutlined style={{ color: '#70b5ff', marginRight: 5 }} />
                <span>{user.goalStats.inProgress} In Progress</span>
              </div>
            </Col>
            <Col xs={8} sm={5} md={4} lg={4}>
              <div className="stat-compact">
                <TrophyOutlined style={{ color: '#9ac2ff', marginRight: 5 }} />
                <span>{user.listStats.created} Lists</span>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      
      <div className="profile-section profile-tabs-section">
        <Tabs defaultActiveKey="1" className="profile-tabs">
          <TabPane tab="My Lists" key="1">
            <Space direction="vertical" size={12} style={{ width: '100%', display: 'flex' }}>
              {sampleLists.map(item => renderListItem(item))}
            </Space>
            <div className="add-custom-button">
              <Button 
                type="dashed" 
                icon={<PlusOutlined />}
                style={{ 
                  borderColor: '#5c9dff',
                  color: '#5c9dff'
                }}
              >
                Create New List
              </Button>
            </div>
          </TabPane>
          
          <TabPane tab="Saved Lists" key="2">
            <Space direction="vertical" size={12} style={{ width: '100%', display: 'flex' }}>
              {sampleSavedLists.map(item => renderSavedListItem(item))}
            </Space>
          </TabPane>
          
          <TabPane tab="Achievements" key="3">
            <div className="milestones-section">              
              <div className="milestones-timeline">
                {sampleCompletedGoals.map((goal, index) => (
                  <div key={index} className="milestone-item milestone-completed">
                    <div className="milestone-marker"></div>
                    <div className="milestone-content">
                      <div className="milestone-header">
                        <div className="milestone-title">{goal.title}</div>
                        <div className="milestone-date">{goal.completedDate}</div>
                      </div>
                      <div className="milestone-note">{goal.itemsCompleted} items completed</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </TabPane>
          
          <TabPane tab={<span><CalendarOutlined /> Growth Milestones</span>} key="4">
            <div className="milestones-section">
              <div className="plan-title-section">
                <div className="section-title-with-icon">
                  <i className="section-icon">🌱</i>
                  <Title level={4} style={{ fontSize: '16px', margin: 0 }}>Emma's Growth Journey</Title>
                </div>
                <Button
                  type="default"
                  icon={<PlusOutlined />}
                  size="small"
                  style={{
                    borderRadius: '18px',
                    fontSize: '13px',
                    color: '#5c9dff',
                    borderColor: '#d0dcff',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  Add Milestone
                </Button>
              </div>
              
              <div className="milestones-timeline">
                {sampleGrowthMilestones.map((milestone, index) => (
                  <div key={index} className={`milestone-item milestone-${milestone.status}`}>
                    <div className="milestone-marker"></div>
                    <div className="milestone-content">
                      <div className="milestone-header">
                        <div className="milestone-title">{milestone.title}</div>
                        <div className="milestone-date">{milestone.date}</div>
                      </div>
                      <div className="milestone-note">{milestone.note}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </TabPane>
          
          <TabPane tab="Following" key="5">
            <List
              dataSource={samplePeople}
              renderItem={item => (
                <List.Item>
                  <Card className="person-card">
                    <div className="person-card-content">
                      <Avatar 
                        size="large" 
                        icon={<UserOutlined />}
                        src={item.photo}
                      />
                      <div className="person-details">
                        <Text strong>{item.name}</Text>
                        <Tag color="#5c9dff">{item.expertise}</Tag>
                      </div>
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Profile;