import React, { useState } from 'react';
import { 
  Modal, 
  Button, 
  Tabs, 
  Input, 
  Typography, 
  Space, 
  message, 
  Switch, 
  Divider,
  Tooltip
} from 'antd';
import { 
  ShareAltOutlined, 
  CopyOutlined, 
  WhatsAppOutlined, 
  MailOutlined, 
  FacebookOutlined, 
  TwitterOutlined,
  FileTextOutlined,
  QrcodeOutlined
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

/**
 * A reusable share modal component
 * @param {Object} props
 * @param {boolean} props.visible - Whether the modal is visible
 * @param {Function} props.onCancel - Function to call when closing the modal
 * @param {string} props.itemType - Type of item being shared (plan, list, product, brand)
 * @param {string} props.itemName - Name of the item being shared
 * @param {string} props.itemId - ID of the item being shared
 */
const ShareModal = ({ visible, onCancel, itemType, itemName, itemId }) => {
  // State for personal message
  const [personalMessage, setPersonalMessage] = useState('');
  const [privacyOptions, setPrivacyOptions] = useState({
    includeNotes: true,
    includePrices: true,
    includeAlternatives: true
  });
  const [activeTab, setActiveTab] = useState('link');
  
  // Generate a shareable link
  const getSharableLink = () => {
    // In a real app, this would generate a proper deep link
    // For demo purposes, we'll create a simple URL
    const baseUrl = window.location.origin;
    const shareRoute = `/shared/${itemType}/${itemId}`;
    
    // Add options as query params
    let queryParams = [];
    if (!privacyOptions.includeNotes) queryParams.push('notes=0');
    if (!privacyOptions.includePrices) queryParams.push('prices=0');
    if (!privacyOptions.includeAlternatives) queryParams.push('alternatives=0');
    
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    
    return `${baseUrl}${shareRoute}${queryString}`;
  };
  
  // Handle copy link to clipboard
  const handleCopyLink = () => {
    const link = getSharableLink();
    navigator.clipboard.writeText(link)
      .then(() => {
        message.success('Link copied to clipboard!');
      })
      .catch(err => {
        message.error('Failed to copy link: ' + err.message);
      });
  };
  
  // Handle share via different platforms
  const handleShareViaWhatsApp = () => {
    const link = getSharableLink();
    const text = personalMessage 
      ? `${personalMessage} - Check out my ${itemType}: ${itemName} ${link}`
      : `Check out my ${itemType}: ${itemName} ${link}`;
    window.open(`https://wa.me/?text=${encodeURIComponent(text)}`);
  };
  
  const handleShareViaEmail = () => {
    const link = getSharableLink();
    const subject = `Check out my ${itemType}: ${itemName}`;
    const body = personalMessage 
      ? `${personalMessage}\n\nView it here: ${link}`
      : `I wanted to share my ${itemType} "${itemName}" with you.\n\nView it here: ${link}`;
    window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
  };
  
  const handleShareViaFacebook = () => {
    const link = getSharableLink();
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`);
  };
  
  const handleShareViaTwitter = () => {
    const link = getSharableLink();
    const text = `Check out my ${itemType}: ${itemName}`;
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(link)}`);
  };
  
  // Handle generating PDF (this would be implemented in a real app)
  const handleGeneratePDF = () => {
    message.info('Generating PDF... This feature would be implemented in a real app.');
  };
  
  // Handle generating QR code (this would be implemented in a real app)
  const handleGenerateQRCode = () => {
    message.info('Generating QR Code... This feature would be implemented in a real app.');
  };
  
  // Handle personal message change
  const handleMessageChange = (e) => {
    setPersonalMessage(e.target.value);
  };
  
  // Handle privacy option toggle
  const handlePrivacyOptionChange = (option, checked) => {
    setPrivacyOptions(prev => ({
      ...prev,
      [option]: checked
    }));
  };
  
  return (
    <Modal
      title={<span><ShareAltOutlined /> Share {itemType.charAt(0).toUpperCase() + itemType.slice(1)}</span>}
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={600}
    >
      <div className="share-modal-content">
        <div className="share-preview-section" style={{ marginBottom: '20px' }}>
          <Title level={4} style={{ fontSize: '18px', marginBottom: '8px' }}>
            Sharing: {itemName}
          </Title>
          {personalMessage && (
            <div style={{ marginBottom: '12px', background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
              <Text type="secondary">Personal message:</Text>
              <Paragraph>{personalMessage}</Paragraph>
            </div>
          )}
        </div>

        <div className="share-message-section" style={{ marginBottom: '20px' }}>
          <Title level={5}>Add a Personal Message</Title>
          <TextArea
            placeholder={`Add a personal message about this ${itemType}...`}
            value={personalMessage}
            onChange={handleMessageChange}
            rows={3}
            style={{ marginBottom: '12px' }}
          />
        </div>
        
        <div className="share-privacy-section" style={{ marginBottom: '20px' }}>
          <Title level={5}>Privacy Options</Title>
          <Space direction="vertical" style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text>Include My Notes</Text>
              <Switch 
                checked={privacyOptions.includeNotes} 
                onChange={(checked) => handlePrivacyOptionChange('includeNotes', checked)}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text>Include Prices</Text>
              <Switch 
                checked={privacyOptions.includePrices} 
                onChange={(checked) => handlePrivacyOptionChange('includePrices', checked)}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text>Include Alternative Products</Text>
              <Switch 
                checked={privacyOptions.includeAlternatives} 
                onChange={(checked) => handlePrivacyOptionChange('includeAlternatives', checked)}
              />
            </div>
          </Space>
        </div>
        
        <Divider />
        
        <div className="share-options-section">
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Copy Link" key="link">
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <Input 
                  value={getSharableLink()} 
                  readOnly 
                  style={{ flex: 1, marginRight: '8px' }}
                />
                <Button 
                  type="primary" 
                  icon={<CopyOutlined />} 
                  onClick={handleCopyLink}
                >
                  Copy
                </Button>
              </div>
              <Paragraph type="secondary">
                This link will allow others to view your {itemType}. They can view it without signing up.
              </Paragraph>
            </TabPane>
            
            <TabPane tab="Social Share" key="social">
              <Space style={{ marginBottom: '16px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Tooltip title="Share via WhatsApp">
                  <Button 
                    icon={<WhatsAppOutlined />} 
                    size="large" 
                    shape="circle" 
                    onClick={handleShareViaWhatsApp}
                    style={{ background: '#25D366', color: 'white' }}
                  />
                </Tooltip>
                <Tooltip title="Share via Email">
                  <Button 
                    icon={<MailOutlined />} 
                    size="large" 
                    shape="circle" 
                    onClick={handleShareViaEmail}
                    style={{ background: '#D44638', color: 'white' }}
                  />
                </Tooltip>
                <Tooltip title="Share via Facebook">
                  <Button 
                    icon={<FacebookOutlined />} 
                    size="large" 
                    shape="circle" 
                    onClick={handleShareViaFacebook}
                    style={{ background: '#3b5998', color: 'white' }}
                  />
                </Tooltip>
                <Tooltip title="Share via Twitter">
                  <Button 
                    icon={<TwitterOutlined />} 
                    size="large" 
                    shape="circle" 
                    onClick={handleShareViaTwitter}
                    style={{ background: '#1DA1F2', color: 'white' }}
                  />
                </Tooltip>
              </Space>
              <Paragraph type="secondary" style={{ textAlign: 'center' }}>
                Share this {itemType} directly to your favorite platform.
              </Paragraph>
            </TabPane>
            
            <TabPane tab="Export" key="export">
              <Space direction="vertical" style={{ width: '100%' }}>
                <Button 
                  icon={<FileTextOutlined />} 
                  block 
                  onClick={handleGeneratePDF}
                  style={{ height: '40px', marginBottom: '12px' }}
                >
                  Generate PDF
                </Button>
                <Button 
                  icon={<QrcodeOutlined />} 
                  block 
                  onClick={handleGenerateQRCode}
                  style={{ height: '40px' }}
                >
                  Generate QR Code
                </Button>
              </Space>
              <Paragraph type="secondary" style={{ marginTop: '16px' }}>
                Export options let you save or print your {itemType} for offline use.
              </Paragraph>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;