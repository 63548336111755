import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { 
  UnorderedListOutlined, 
  CompassOutlined, 
  MessageOutlined, 
  UserOutlined 
} from '@ant-design/icons';
import '../../../assets/css/components/navigation.css';

const MainNavigation = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  
  // Determine which nav item should be active
  const getSelectedKey = () => {
    if (currentPath.includes('/plan')) return '1';
    if (currentPath.includes('/explore')) return '2';
    if (currentPath.includes('/chat')) return '3';
    if (currentPath.includes('/profile')) return '4';
    return '1'; // Default to plan
  };

  return (
    <div className="main-navigation">
      <Menu
        mode="horizontal"
        selectedKeys={[getSelectedKey()]}
        className="nav-menu"
      >
        <Menu.Item key="1" style={{ flex: 1 }}>
          <div className="nav-item-content">
            <UnorderedListOutlined className="nav-icon" />
            <Link to="/plan">Plan</Link>
          </div>
        </Menu.Item>
        <Menu.Item key="2" style={{ flex: 1 }}>
          <div className="nav-item-content">
            <CompassOutlined className="nav-icon" />
            <Link to="/explore">Explore</Link>
          </div>
        </Menu.Item>
        <Menu.Item key="3" style={{ flex: 1 }}>
          <div className="nav-item-content">
            <MessageOutlined className="nav-icon" />
            <Link to="/chat">Chat</Link>
          </div>
        </Menu.Item>
        <Menu.Item key="4" style={{ flex: 1 }}>
          <div className="nav-item-content">
            <UserOutlined className="nav-icon" />
            <Link to="/profile">Profile</Link>
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MainNavigation;