export default {
  // User lists
  userLists: [],
  readUserListsInProcess: false,
  readUserListsError: null,
  
  // Suggested lists
  suggestedLists: [],
  readSuggestedListsInProcess: false,
  readSuggestedListsError: null,
  
  // Suggested list items
  suggestedListItems: [],
  readSuggestedListItemsInProcess: false,
  readSuggestedListItemsError: null,
  
  // Single list details
  list: null,
  readListInProcess: false,
  readListError: null,
  
  // Create list
  createListInProcess: false,
  createListError: null,
  
  // Update list
  updateListInProcess: false,
  updateListError: null,
  
  // Delete list
  deleteListInProcess: false,
  deleteListError: null,
  
  // Accept list suggestion
  acceptListSuggestionInProcess: false,
  acceptListSuggestionError: null,
  
  // Reject list suggestion
  rejectListSuggestionInProcess: false,
  rejectListSuggestionError: null,
  
  // Generate list suggestions
  generateListSuggestionsInProcess: false,
  generateListSuggestionsError: null,
  
  // Generate list item suggestions
  generateListItemSuggestionsInProcess: false,
  generateListItemSuggestionsError: null,
  
  // Add item to list
  addItemToListInProcess: false,
  addItemToListError: null
};