import axios from 'axios';
import * as types from './actionTypes';
import {isEmpty} from '../../helperFunctions';


/**
 * Service for interacting with ChatGPT API endpoints
 */
class ChatGPTService {
  /**
   * Send a chat completion request to the backend
   *
   * @param {Array} messages - Array of message objects with role and content
   * @param {Object} options - Additional options like temperature and max_tokens
   * @returns {Promise} Response from the API
   */
  static async getChatCompletion(messages, options = {}) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat/completion`,
        {
          messages,
          temperature: options.temperature || 0.7,
          max_tokens: options.max_tokens || 2000
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+sessionStorage.getItem('jwt')
          }
        }
      );

      if (response.data.Success) {
        return response.data;
      } else {
        throw new Error(response.data.Error || 'Failed to get chat completion');
      }

    } catch (error) {
      // Log error for debugging
      console.error('Error in getChatCompletion:', error);

      // Throw a formatted error
      throw {
        message: error.response?.data?.Error || 'Failed to get chat completion',
        status: error.response?.status,
        originalError: error
      };
    }
  }

  /**
   * Helper function to format messages for the API
   *
   * @param {string} userMessage - The user's message
   * @param {string} systemPrompt - Optional system prompt to set context
   * @param {Array} previousMessages - Optional array of previous messages for context
   * @returns {Array} Formatted messages array
   */
  static formatMessages(userMessage, systemPrompt = null, previousMessages = []) {
    const messages = [];

    if (systemPrompt) {
      messages.push({
        role: 'system',
        content: systemPrompt
      });
    }

    // Add previous messages to maintain context
    if (previousMessages && previousMessages.length > 0) {
      // Only include the last 10 messages to avoid token limits
      const contextMessages = previousMessages.slice(-10);
      messages.push(...contextMessages);
    }

    // Add the current user message
    messages.push({
      role: 'user',
      content: userMessage
    });

    return messages;
  }
}

export default ChatGPTService;
