// Read lists
export const READ_USER_LISTS_IN_PROCESS = 'READ_USER_LISTS_IN_PROCESS';
export const READ_USER_LISTS_SUCCESS = 'READ_USER_LISTS_SUCCESS';
export const READ_USER_LISTS_ERROR = 'READ_USER_LISTS_ERROR';

// Read suggested lists
export const READ_SUGGESTED_LISTS_IN_PROCESS = 'READ_SUGGESTED_LISTS_IN_PROCESS';
export const READ_SUGGESTED_LISTS_SUCCESS = 'READ_SUGGESTED_LISTS_SUCCESS';
export const READ_SUGGESTED_LISTS_ERROR = 'READ_SUGGESTED_LISTS_ERROR';

// Read list details
export const READ_LIST_IN_PROCESS = 'READ_LIST_IN_PROCESS';
export const READ_LIST_SUCCESS = 'READ_LIST_SUCCESS';
export const READ_LIST_ERROR = 'READ_LIST_ERROR';

// Create list
export const CREATE_LIST_IN_PROCESS = 'CREATE_LIST_IN_PROCESS';
export const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS';
export const CREATE_LIST_ERROR = 'CREATE_LIST_ERROR';

// Update list
export const UPDATE_LIST_IN_PROCESS = 'UPDATE_LIST_IN_PROCESS';
export const UPDATE_LIST_SUCCESS = 'UPDATE_LIST_SUCCESS';
export const UPDATE_LIST_ERROR = 'UPDATE_LIST_ERROR';

// Delete list
export const DELETE_LIST_IN_PROCESS = 'DELETE_LIST_IN_PROCESS';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const DELETE_LIST_ERROR = 'DELETE_LIST_ERROR';

// Accept list suggestion
export const ACCEPT_LIST_SUGGESTION_IN_PROCESS = 'ACCEPT_LIST_SUGGESTION_IN_PROCESS';
export const ACCEPT_LIST_SUGGESTION_SUCCESS = 'ACCEPT_LIST_SUGGESTION_SUCCESS';
export const ACCEPT_LIST_SUGGESTION_ERROR = 'ACCEPT_LIST_SUGGESTION_ERROR';

// Reject list suggestion
export const REJECT_LIST_SUGGESTION_IN_PROCESS = 'REJECT_LIST_SUGGESTION_IN_PROCESS';
export const REJECT_LIST_SUGGESTION_SUCCESS = 'REJECT_LIST_SUGGESTION_SUCCESS';
export const REJECT_LIST_SUGGESTION_ERROR = 'REJECT_LIST_SUGGESTION_ERROR';

// Generate list suggestions
export const GENERATE_LIST_SUGGESTIONS_IN_PROCESS = 'GENERATE_LIST_SUGGESTIONS_IN_PROCESS';
export const GENERATE_LIST_SUGGESTIONS_SUCCESS = 'GENERATE_LIST_SUGGESTIONS_SUCCESS';
export const GENERATE_LIST_SUGGESTIONS_ERROR = 'GENERATE_LIST_SUGGESTIONS_ERROR';

// Generate list item suggestions
export const GENERATE_LIST_ITEM_SUGGESTIONS_IN_PROCESS = 'GENERATE_LIST_ITEM_SUGGESTIONS_IN_PROCESS';
export const GENERATE_LIST_ITEM_SUGGESTIONS_SUCCESS = 'GENERATE_LIST_ITEM_SUGGESTIONS_SUCCESS';
export const GENERATE_LIST_ITEM_SUGGESTIONS_ERROR = 'GENERATE_LIST_ITEM_SUGGESTIONS_ERROR';

// Read suggested list items
export const READ_SUGGESTED_LIST_ITEMS_IN_PROCESS = 'READ_SUGGESTED_LIST_ITEMS_IN_PROCESS';
export const READ_SUGGESTED_LIST_ITEMS_SUCCESS = 'READ_SUGGESTED_LIST_ITEMS_SUCCESS';
export const READ_SUGGESTED_LIST_ITEMS_ERROR = 'READ_SUGGESTED_LIST_ITEMS_ERROR';

// Add item to list
export const ADD_ITEM_TO_LIST_IN_PROCESS = 'ADD_ITEM_TO_LIST_IN_PROCESS';
export const ADD_ITEM_TO_LIST_SUCCESS = 'ADD_ITEM_TO_LIST_SUCCESS';
export const ADD_ITEM_TO_LIST_ERROR = 'ADD_ITEM_TO_LIST_ERROR';

// Clear lists
export const CLEAR_LIST_DATA = 'CLEAR_LIST_DATA';
export const CLEAR_LIST_ERRORS = 'CLEAR_LIST_ERRORS';