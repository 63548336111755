import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Space, Typography, Divider, Select, DatePicker, Form } from 'antd';
import { updateUser } from '../../../redux/users/actions';
import { updateCategories } from '../../../redux/categories/actions';

const { Title, Text } = Typography;
const { Option } = Select;

const DebugTester = () => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth?.user || {});
  const categoriesState = useSelector(state => state.categories || {});
  const [lastAction, setLastAction] = useState('None');
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  // Predefined test cases
  const testCases = {
    expectingFirstBaby: {
      firstName: 'Sarah',
      userType: 'expecting',
      isFirstBaby: true,
      pregnancyStatus: 'expecting',
      due_date: '2024-08-15',
      childrenCount: 0
    },
    expectingSecondBaby: {
      firstName: 'Mike',
      userType: 'expecting',
      isFirstBaby: false,
      pregnancyStatus: 'expecting',
      due_date: '2024-09-20',
      childrenCount: 1
    },
    newbornFirstBaby: {
      firstName: 'Emma',
      userType: 'expecting',
      isFirstBaby: true,
      pregnancyStatus: 'born',
      birth_date: '2024-02-01',
      childrenCount: 0
    },
    experiencedNewborn: {
      firstName: 'Jack',
      userType: 'expecting',
      isFirstBaby: false,
      pregnancyStatus: 'born',
      birth_date: '2024-01-15',
      childrenCount: 2
    },
    helperFriend: {
      firstName: 'Jane',
      userType: 'helping',
      helpingFirstTimer: true
    },
    helperExperienced: {
      firstName: 'Tom',
      userType: 'helping',
      helpingFirstTimer: false
    }
  };

  const runPredefinedTest = async (testCase, name) => {
    setError(null);
    try {
      await dispatch(updateUser(userData, testCase));
      setLastAction(`Updated user info for ${name} scenario`);

      // Update categories if helper or experienced parent
      if (testCase.userType === 'helping' || 
          (testCase.userType === 'expecting' && !testCase.isFirstBaby)) {
        const updates = {
          checkedCategories: {
            1: { status: 'likely_have', showNotes: true },
            2: { status: 'likely_have', showNotes: true }
          }
        };
        await dispatch(updateCategories(updates));
        setLastAction(`Updated user info and categories for ${name} scenario`);
      }
    } catch (err) {
      setError(`Error in ${name} scenario: ${err.message}`);
    }
  };

  const handleCustomTest = async (values) => {
    setError(null);
    try {
      const testData = {
        firstName: values.firstName,
        userType: values.userType,
        isFirstBaby: values.isFirstBaby,
        pregnancyStatus: values.pregnancyStatus,
        childrenCount: values.isFirstBaby ? 0 : values.childrenCount || 1
      };

      // Add date based on pregnancy status
      if (values.pregnancyStatus === 'born') {
        testData.birth_date = values.date.toISOString();
      } else {
        testData.due_date = values.date.toISOString();
      }

      await dispatch(updateUser(userData, testData));
      setLastAction('Updated user info with custom scenario');

      // Update categories if applicable
      if (values.userType === 'helping' || 
          (values.userType === 'expecting' && !values.isFirstBaby)) {
        const updates = {
          checkedCategories: {
            1: { status: 'likely_have', showNotes: true },
            2: { status: 'likely_have', showNotes: true }
          }
        };
        await dispatch(updateCategories(updates));
        setLastAction('Updated user info and categories with custom scenario');
      }
    } catch (err) {
      setError(`Error in custom scenario: ${err.message}`);
    }
  };

  return (
    <Card style={{ width: '100%', maxWidth: '800px', margin: '20px auto' }}>
      <Title level={4}>Redux Update Tester</Title>
      <Divider />
      
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <div>
          <Title level={5}>Predefined Test Scenarios</Title>
          <Space wrap>
            <Button type="primary" onClick={() => runPredefinedTest(testCases.expectingFirstBaby, 'Expecting First Baby')}>
              Expecting First Baby
            </Button>
            <Button type="primary" onClick={() => runPredefinedTest(testCases.expectingSecondBaby, 'Expecting Second Baby')}>
              Expecting Second Baby
            </Button>
            <Button type="primary" onClick={() => runPredefinedTest(testCases.newbornFirstBaby, 'Newborn First Baby')}>
              Newborn First Baby
            </Button>
            <Button type="primary" onClick={() => runPredefinedTest(testCases.experiencedNewborn, 'Experienced with Newborn')}>
              Experienced with Newborn
            </Button>
            <Button type="primary" onClick={() => runPredefinedTest(testCases.helperFriend, 'Helper - First Timer')}>
              Helper (First Timer)
            </Button>
            <Button type="primary" onClick={() => runPredefinedTest(testCases.helperExperienced, 'Helper - Experienced')}>
              Helper (Experienced)
            </Button>
          </Space>
        </div>

        <Divider />

        <div>
          <Title level={5}>Custom Test Scenario</Title>
          <Form
            form={form}
            onFinish={handleCustomTest}
            layout="vertical"
            style={{ maxWidth: '600px' }}
          >
            <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
              <Select placeholder="Select test name">
                <Option value="Sarah">Sarah</Option>
                <Option value="Mike">Mike</Option>
                <Option value="Emma">Emma</Option>
                <Option value="Custom">Custom</Option>
              </Select>
            </Form.Item>

            <Form.Item name="userType" label="User Type" rules={[{ required: true }]}>
              <Select placeholder="Select user type">
                <Option value="expecting">Expecting/Parent</Option>
                <Option value="helping">Helper</Option>
              </Select>
            </Form.Item>

            <Form.Item name="isFirstBaby" label="First Baby?" rules={[{ required: true }]}>
              <Select placeholder="Select first baby status">
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>

            <Form.Item name="pregnancyStatus" label="Pregnancy Status" rules={[{ required: true }]}>
              <Select placeholder="Select pregnancy status">
                <Option value="expecting">Expecting</Option>
                <Option value="born">Born</Option>
              </Select>
            </Form.Item>

            <Form.Item name="date" label="Due Date or Birth Date" rules={[{ required: true }]}>
              <DatePicker />
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.isFirstBaby !== currentValues.isFirstBaby}
            >
              {({ getFieldValue }) => 
                getFieldValue('isFirstBaby') === false && (
                  <Form.Item name="childrenCount" label="Number of Children" rules={[{ required: true }]}>
                    <Select placeholder="Select number of children">
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                      <Option value={3}>3+</Option>
                    </Select>
                  </Form.Item>
                )
              }
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Run Custom Test
              </Button>
            </Form.Item>
          </Form>
        </div>

        <Divider />

        <div>
          <Title level={5}>Current State</Title>
          <Card style={{ backgroundColor: '#f5f5f5' }}>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Text strong>Last Action: {lastAction}</Text>
              <Text strong>API URL: {process.env.REACT_APP_API_URL || 'Not set'}</Text>
              <Text strong>NODE_ENV: {process.env.NODE_ENV || 'Not set'}</Text>
              {error && (
                <Text type="danger">Error: {error}</Text>
              )}
              <div>
                <Text strong>User Data:</Text>
                <pre style={{ whiteSpace: 'pre-wrap', overflow: 'auto' }}>
                  {JSON.stringify(userData, null, 2)}
                </pre>
              </div>
              <div>
                <Text strong>Categories State:</Text>
                <pre style={{ whiteSpace: 'pre-wrap', overflow: 'auto' }}>
                  {JSON.stringify(categoriesState, null, 2)}
                </pre>
              </div>
            </Space>
          </Card>
        </div>
      </Space>
    </Card>
  );
};

export default DebugTester;