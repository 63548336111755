// Read goals
export const READ_USER_GOALS_IN_PROCESS = 'READ_USER_GOALS_IN_PROCESS';
export const READ_USER_GOALS_SUCCESS = 'READ_USER_GOALS_SUCCESS';
export const READ_USER_GOALS_ERROR = 'READ_USER_GOALS_ERROR';

// Read suggested goals
export const READ_SUGGESTED_GOALS_IN_PROCESS = 'READ_SUGGESTED_GOALS_IN_PROCESS';
export const READ_SUGGESTED_GOALS_SUCCESS = 'READ_SUGGESTED_GOALS_SUCCESS';
export const READ_SUGGESTED_GOALS_ERROR = 'READ_SUGGESTED_GOALS_ERROR';

// Read goal details
export const READ_GOAL_IN_PROCESS = 'READ_GOAL_IN_PROCESS';
export const READ_GOAL_SUCCESS = 'READ_GOAL_SUCCESS';
export const READ_GOAL_ERROR = 'READ_GOAL_ERROR';

// Create goal
export const CREATE_GOAL_IN_PROCESS = 'CREATE_GOAL_IN_PROCESS';
export const CREATE_GOAL_SUCCESS = 'CREATE_GOAL_SUCCESS';
export const CREATE_GOAL_ERROR = 'CREATE_GOAL_ERROR';

// Update goal
export const UPDATE_GOAL_IN_PROCESS = 'UPDATE_GOAL_IN_PROCESS';
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS';
export const UPDATE_GOAL_ERROR = 'UPDATE_GOAL_ERROR';

// Delete goal
export const DELETE_GOAL_IN_PROCESS = 'DELETE_GOAL_IN_PROCESS';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_ERROR = 'DELETE_GOAL_ERROR';

// Accept goal
export const ACCEPT_GOAL_IN_PROCESS = 'ACCEPT_GOAL_IN_PROCESS';
export const ACCEPT_GOAL_SUCCESS = 'ACCEPT_GOAL_SUCCESS';
export const ACCEPT_GOAL_ERROR = 'ACCEPT_GOAL_ERROR';

// Reject goal
export const REJECT_GOAL_IN_PROCESS = 'REJECT_GOAL_IN_PROCESS';
export const REJECT_GOAL_SUCCESS = 'REJECT_GOAL_SUCCESS';
export const REJECT_GOAL_ERROR = 'REJECT_GOAL_ERROR';

// Create goal item
export const CREATE_GOAL_ITEM_IN_PROCESS = 'CREATE_GOAL_ITEM_IN_PROCESS';
export const CREATE_GOAL_ITEM_SUCCESS = 'CREATE_GOAL_ITEM_SUCCESS';
export const CREATE_GOAL_ITEM_ERROR = 'CREATE_GOAL_ITEM_ERROR';

// Add items to goal
export const ADD_ITEMS_TO_GOAL_IN_PROCESS = 'ADD_ITEMS_TO_GOAL_IN_PROCESS';
export const ADD_ITEMS_TO_GOAL_SUCCESS = 'ADD_ITEMS_TO_GOAL_SUCCESS';
export const ADD_ITEMS_TO_GOAL_ERROR = 'ADD_ITEMS_TO_GOAL_ERROR';

// Update goal item
export const UPDATE_GOAL_ITEM_IN_PROCESS = 'UPDATE_GOAL_ITEM_IN_PROCESS';
export const UPDATE_GOAL_ITEM_SUCCESS = 'UPDATE_GOAL_ITEM_SUCCESS';
export const UPDATE_GOAL_ITEM_ERROR = 'UPDATE_GOAL_ITEM_ERROR';

// Delete goal item
export const DELETE_GOAL_ITEM_IN_PROCESS = 'DELETE_GOAL_ITEM_IN_PROCESS';
export const DELETE_GOAL_ITEM_SUCCESS = 'DELETE_GOAL_ITEM_SUCCESS';
export const DELETE_GOAL_ITEM_ERROR = 'DELETE_GOAL_ITEM_ERROR';

// Update goal item status
export const UPDATE_GOAL_ITEM_STATUS_IN_PROCESS = 'UPDATE_GOAL_ITEM_STATUS_IN_PROCESS';
export const UPDATE_GOAL_ITEM_STATUS_SUCCESS = 'UPDATE_GOAL_ITEM_STATUS_SUCCESS';
export const UPDATE_GOAL_ITEM_STATUS_ERROR = 'UPDATE_GOAL_ITEM_STATUS_ERROR';

// Clear goals
export const CLEAR_GOAL_DATA = 'CLEAR_GOAL_DATA';
export const CLEAR_GOAL_ERRORS = 'CLEAR_GOAL_ERRORS';