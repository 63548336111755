import initialState from './initialState';
import * as types from './actionTypes';

const goalsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Read user goals
    case types.READ_USER_GOALS_IN_PROCESS:
      return {
        ...state,
        userGoalsLoading: true,
        userGoalsError: null
      };
    case types.READ_USER_GOALS_SUCCESS:
      return {
        ...state,
        userGoals: action.userGoals,
        progress: action.progress,
        stats: action.stats,
        userGoalsLoading: false
      };
    case types.READ_USER_GOALS_ERROR:
      return {
        ...state,
        userGoalsLoading: false,
        userGoalsError: action.error
      };
      
    // Read suggested goals
    case types.READ_SUGGESTED_GOALS_IN_PROCESS:
      return {
        ...state,
        suggestedGoalsLoading: true,
        suggestedGoalsError: null
      };
    case types.READ_SUGGESTED_GOALS_SUCCESS:
      return {
        ...state,
        suggestedGoals: action.suggestedGoals,
        suggestedGoalsLoading: false
      };
    case types.READ_SUGGESTED_GOALS_ERROR:
      return {
        ...state,
        suggestedGoalsLoading: false,
        suggestedGoalsError: action.error
      };
      
    // Read goal details
    case types.READ_GOAL_IN_PROCESS:
      return {
        ...state,
        currentGoalLoading: true,
        currentGoalError: null
      };
    case types.READ_GOAL_SUCCESS:
      return {
        ...state,
        currentGoal: action.goal,
        currentGoalLoading: false
      };
    case types.READ_GOAL_ERROR:
      return {
        ...state,
        currentGoalLoading: false,
        currentGoalError: action.error
      };
      
    // Create goal
    case types.CREATE_GOAL_IN_PROCESS:
      return {
        ...state,
        createGoalLoading: true,
        createGoalError: null
      };
    case types.CREATE_GOAL_SUCCESS:
      return {
        ...state,
        userGoals: [action.goal, ...state.userGoals],
        createGoalLoading: false
      };
    case types.CREATE_GOAL_ERROR:
      return {
        ...state,
        createGoalLoading: false,
        createGoalError: action.error
      };
      
    // Update goal
    case types.UPDATE_GOAL_IN_PROCESS:
      return {
        ...state,
        updateGoalLoading: true,
        updateGoalError: null
      };
    case types.UPDATE_GOAL_SUCCESS:
      return {
        ...state,
        userGoals: state.userGoals.map(goal => 
          goal.uuid === action.goal.uuid ? { ...goal, ...action.goal } : goal
        ),
        currentGoal: state.currentGoal && state.currentGoal.uuid === action.goal.uuid 
          ? { ...state.currentGoal, ...action.goal } 
          : state.currentGoal,
        updateGoalLoading: false
      };
    case types.UPDATE_GOAL_ERROR:
      return {
        ...state,
        updateGoalLoading: false,
        updateGoalError: action.error
      };
      
    // Delete goal
    case types.DELETE_GOAL_IN_PROCESS:
      return {
        ...state,
        deleteGoalLoading: true,
        deleteGoalError: null
      };
    case types.DELETE_GOAL_SUCCESS:
      return {
        ...state,
        userGoals: state.userGoals.filter(goal => goal.uuid !== action.goalUuid),
        currentGoal: state.currentGoal && state.currentGoal.uuid === action.goalUuid 
          ? null 
          : state.currentGoal,
        deleteGoalLoading: false
      };
    case types.DELETE_GOAL_ERROR:
      return {
        ...state,
        deleteGoalLoading: false,
        deleteGoalError: action.error
      };
      
    // Accept goal
    case types.ACCEPT_GOAL_IN_PROCESS:
      return {
        ...state,
        acceptGoalLoading: true,
        acceptGoalError: null
      };
    case types.ACCEPT_GOAL_SUCCESS:
      const suggestedGoal = state.suggestedGoals.find(goal => goal.uuid === action.goal.uuid);
      return {
        ...state,
        userGoals: [
          {
            ...action.goal,
            is_suggested: true,
            template_reason: suggestedGoal ? suggestedGoal.template_reason : '',
            accepted: true,
            status: 'active',
            stats: { 
              total_items: 0, 
              completed_items: 0, 
              completion_percentage: 0, 
              sampling_items: 0, 
              training_items: 0, 
              building_items: 0
            }
          },
          ...state.userGoals
        ],
        suggestedGoals: state.suggestedGoals.filter(goal => goal.uuid !== action.goal.uuid),
        acceptGoalLoading: false
      };
    case types.ACCEPT_GOAL_ERROR:
      return {
        ...state,
        acceptGoalLoading: false,
        acceptGoalError: action.error
      };
      
    // Reject goal
    case types.REJECT_GOAL_IN_PROCESS:
      return {
        ...state,
        acceptGoalLoading: true,
        acceptGoalError: null
      };
    case types.REJECT_GOAL_SUCCESS:
      return {
        ...state,
        suggestedGoals: state.suggestedGoals.filter(goal => goal.uuid !== action.goalUuid),
        acceptGoalLoading: false
      };
    case types.REJECT_GOAL_ERROR:
      return {
        ...state,
        acceptGoalLoading: false,
        acceptGoalError: action.error
      };
      
    // Create goal item
    case types.CREATE_GOAL_ITEM_IN_PROCESS:
      return {
        ...state,
        createGoalItemLoading: true,
        createGoalItemError: null
      };
    case types.CREATE_GOAL_ITEM_SUCCESS:
      // If this item belongs to the current goal, add it to the appropriate module
      if (state.currentGoal && action.goalUuid === state.currentGoal.uuid) {
        const module = action.item.module || 'Other';
        const updatedItemsByModule = { ...state.currentGoal.items_by_module };
        
        if (updatedItemsByModule[module]) {
          updatedItemsByModule[module] = [...updatedItemsByModule[module], action.item];
        } else {
          updatedItemsByModule[module] = [action.item];
        }
        
        return {
          ...state,
          currentGoal: {
            ...state.currentGoal,
            items_by_module: updatedItemsByModule,
            stats: {
              ...state.currentGoal.stats,
              total_items: state.currentGoal.stats.total_items + 1
            }
          },
          createGoalItemLoading: false
        };
      }
      
      return {
        ...state,
        createGoalItemLoading: false
      };
      
    case types.CREATE_GOAL_ITEM_ERROR:
      return {
        ...state,
        createGoalItemLoading: false,
        createGoalItemError: action.error
      };
      
    // Add items to goal
    case types.ADD_ITEMS_TO_GOAL_IN_PROCESS:
      return {
        ...state,
        addItemsToGoalLoading: true,
        addItemsToGoalError: null
      };
    case types.ADD_ITEMS_TO_GOAL_SUCCESS:
      // Refresh the current goal since multiple items were added
      return {
        ...state,
        addItemsToGoalLoading: false
      };
    case types.ADD_ITEMS_TO_GOAL_ERROR:
      return {
        ...state,
        addItemsToGoalLoading: false,
        addItemsToGoalError: action.error
      };
      
    // Update goal item
    case types.UPDATE_GOAL_ITEM_IN_PROCESS:
      return {
        ...state,
        updateGoalItemLoading: true,
        updateGoalItemError: null
      };
    case types.UPDATE_GOAL_ITEM_SUCCESS:
      // If this item belongs to the current goal, update it in the right module
      if (state.currentGoal) {
        // Copy the items_by_module
        const updatedItemsByModule = { ...state.currentGoal.items_by_module };
        
        // Find and update the item in its module
        let found = false;
        Object.keys(updatedItemsByModule).forEach(moduleName => {
          if (!found) {
            const moduleItems = updatedItemsByModule[moduleName];
            const itemIndex = moduleItems.findIndex(item => item.uuid === action.item.uuid);
            
            if (itemIndex !== -1) {
              found = true;
              const updatedItems = [...moduleItems];
              updatedItems[itemIndex] = { ...updatedItems[itemIndex], ...action.item };
              updatedItemsByModule[moduleName] = updatedItems;
            }
          }
        });
        
        return {
          ...state,
          currentGoal: {
            ...state.currentGoal,
            items_by_module: updatedItemsByModule
          },
          updateGoalItemLoading: false
        };
      }
      
      return {
        ...state,
        updateGoalItemLoading: false
      };
      
    case types.UPDATE_GOAL_ITEM_ERROR:
      return {
        ...state,
        updateGoalItemLoading: false,
        updateGoalItemError: action.error
      };
      
    // Delete goal item
    case types.DELETE_GOAL_ITEM_IN_PROCESS:
      return {
        ...state,
        deleteGoalItemLoading: true,
        deleteGoalItemError: null
      };
    case types.DELETE_GOAL_ITEM_SUCCESS:
      // If this item belongs to the current goal, remove it from the right module
      if (state.currentGoal) {
        // Copy the items_by_module
        const updatedItemsByModule = { ...state.currentGoal.items_by_module };
        
        // Find and remove the item from its module
        Object.keys(updatedItemsByModule).forEach(moduleName => {
          const moduleItems = updatedItemsByModule[moduleName];
          const updatedItems = moduleItems.filter(item => item.uuid !== action.itemUuid);
          
          if (updatedItems.length !== moduleItems.length) {
            // Item was found and removed
            updatedItemsByModule[moduleName] = updatedItems;
          }
        });
        
        return {
          ...state,
          currentGoal: {
            ...state.currentGoal,
            items_by_module: updatedItemsByModule,
            stats: {
              ...state.currentGoal.stats,
              total_items: state.currentGoal.stats.total_items - 1
            }
          },
          deleteGoalItemLoading: false
        };
      }
      
      return {
        ...state,
        deleteGoalItemLoading: false
      };
      
    case types.DELETE_GOAL_ITEM_ERROR:
      return {
        ...state,
        deleteGoalItemLoading: false,
        deleteGoalItemError: action.error
      };
      
    // Update goal item status
    case types.UPDATE_GOAL_ITEM_STATUS_IN_PROCESS:
      return {
        ...state,
        updateGoalItemStatusLoading: true,
        updateGoalItemStatusError: null
      };
    case types.UPDATE_GOAL_ITEM_STATUS_SUCCESS:
      // If this item belongs to the current goal, update its status
      if (state.currentGoal) {
        // Copy the items_by_module
        const updatedItemsByModule = { ...state.currentGoal.items_by_module };
        let statsUpdate = { ...state.currentGoal.stats };
        let found = false;
        
        // Find and update the item status in its module
        Object.keys(updatedItemsByModule).forEach(moduleName => {
          if (!found) {
            const moduleItems = updatedItemsByModule[moduleName];
            const itemIndex = moduleItems.findIndex(item => item.uuid === action.item.uuid);
            
            if (itemIndex !== -1) {
              found = true;
              const itemWasCompleted = moduleItems[itemIndex].status === 'completed';
              const itemIsCompleted = action.item.status === 'completed';
              
              // Update the item status
              const updatedItems = [...moduleItems];
              updatedItems[itemIndex] = { ...updatedItems[itemIndex], status: action.item.status };
              updatedItemsByModule[moduleName] = updatedItems;
              
              // Update stats
              if (!itemWasCompleted && itemIsCompleted) {
                // Item was marked as completed
                statsUpdate = {
                  ...statsUpdate,
                  completed_items: statsUpdate.completed_items + 1,
                  completion_percentage: Math.floor((statsUpdate.completed_items + 1) / statsUpdate.total_items * 100)
                };
              } else if (itemWasCompleted && !itemIsCompleted) {
                // Item was unmarked as completed
                statsUpdate = {
                  ...statsUpdate,
                  completed_items: Math.max(0, statsUpdate.completed_items - 1),
                  completion_percentage: Math.floor(Math.max(0, statsUpdate.completed_items - 1) / statsUpdate.total_items * 100)
                };
              }
            }
          }
        });
        
        return {
          ...state,
          currentGoal: {
            ...state.currentGoal,
            items_by_module: updatedItemsByModule,
            stats: statsUpdate
          },
          updateGoalItemStatusLoading: false
        };
      }
      
      return {
        ...state,
        updateGoalItemStatusLoading: false
      };
      
    case types.UPDATE_GOAL_ITEM_STATUS_ERROR:
      return {
        ...state,
        updateGoalItemStatusLoading: false,
        updateGoalItemStatusError: action.error
      };
      
    // Clear goals
    case types.CLEAR_GOAL_DATA:
      return initialState;
      
    case types.CLEAR_GOAL_ERRORS:
      return {
        ...state,
        userGoalsError: null,
        suggestedGoalsError: null,
        currentGoalError: null,
        createGoalError: null,
        updateGoalError: null,
        deleteGoalError: null,
        acceptGoalError: null,
        createGoalItemError: null,
        addItemsToGoalError: null,
        updateGoalItemError: null,
        deleteGoalItemError: null,
        updateGoalItemStatusError: null
      };
      
    default:
      return state;
  }
};

export default goalsReducer;