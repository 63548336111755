import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as categoryFunctions from '../../redux/categories/actions';
import * as productFunctions from '../../redux/products/actions';
import NoteForm from '../../components/inputs/NoteForm.js';
import NoteTags from '../../components/inputs/NoteTags.js';
import { Row, Col } from 'antd';
// import StickyNotes from '../../components/lists/StickyNotes.js';
// import AddNote from '../../components/modals/AddNote';


class Notepad extends Component {
  constructor(props, context) {
    super(props, context);

    this.props.readAllCategories();
  }

  render() {
      return (
        <div>
          <p style={styles.headerText}>My notepad</p>
          <p style={styles.subheaderText2}>Keep track of your notes as you prepare for the delivery of your loved one.</p>
          <Row style={styles.inputContainer}
            >
            <Col span={8}>
              <NoteTags/>
            </ Col>
            <Col span={16}>
              <NoteForm
                placeholder="What would you like to note?"
                textAreaStyle={{marginLeft: '5%', marginTop: '2%',width: '90%', height: 500, borderRadius: 5}}
                block={true}
                submitButtonFormItemStyle={{marginLeft: '50%', width: '45%'}}
              />
            </ Col>
          </ Row>
          {/* <StickyNotes /> */}
        </div>
      )
    }
  }


const styles = {
  inputContainer: {
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '5%',
    backgroundColor: '#f2f2f2',
    border: "solid 1px lightgrey",
    borderRadius: 5,
    padding: '1%'
  },
  headerText: {
    paddingBottom: 0,
    fontWeight: 300,
    fontSize: 36,
    marginTop: 50,
    marginLeft: '5%',
    marginBottom: 0
  },
  subheaderText2: {
    paddingBottom: 0,
    fontWeight: 300,
    fontSize: 20,
    marginLeft: '5%',
    color: 'grey'
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    allCategories: state.categories.allCategories,
    readAllCategoriesInProgress: state.categories.readAllCategoriesInProgress,
    readAllCategoriesSuccess: state.categories.readAllCategoriesSuccess,
    readAllCategoriesError: state.categories.readAllCategoriesError,
    products: state.products.products
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    readAllCategories: () => dispatch(categoryFunctions.readAllCategories()),
    readProductsInCategory: (categoryUuid) => dispatch(productFunctions.readProductsInCategory(categoryUuid)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notepad);
