import React, { useState, useEffect } from 'react';
import { 
  useParams, 
  useHistory,
  useLocation
} from 'react-router-dom';
import { 
  Typography, 
  Button, 
  Card, 
  Space, 
  Checkbox, 
  Tabs, 
  Modal, 
  Form, 
  Input, 
  Select,
  Tag,
  Divider,
  Progress,
  Radio,
  Row,
  Col,
  Tooltip,
  Empty,
  List,
  Rate,
  message,
  Breadcrumb
} from 'antd';
import { 
  ArrowLeftOutlined, 
  PlusOutlined, 
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  InfoCircleOutlined,
  UserOutlined,
  ShoppingOutlined,
  CloseOutlined,
  HomeOutlined,
  ShareAltOutlined
} from '@ant-design/icons';
import '../../assets/css/components/plans.css';
import ShareModal from '../components/modals/ShareModal';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const ProductDetails = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  
  // Extract plan ID and product ID from URL params
  const { planId, productId } = params;
  
  // Get product and plan data from location state or fetch it based on IDs
  const [plan, setPlan] = useState(location.state?.planData || null);
  const [product, setProduct] = useState(location.state?.productData || null);
  const [showEmptyRecommendationsState, setShowEmptyRecommendationsState] = useState(true);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  
  // If we don't have the data from location state, we need to fetch it
  useEffect(() => {
    if (!plan || !product) {
      // In a real app, you would fetch the data from an API
      // For this demo, we'll use the mock plans from PlanDetails
      // For a real implementation, this would be an API call
      
      // Mock implementation for demo purposes
      const mockPlans = {
        '123e4567-e89b-12d3-a456-426614174000': {
          uuid: '123e4567-e89b-12d3-a456-426614174000',
          name: 'Get Ready for Baby',
          description: 'Prepare everything for the baby\'s arrival',
          modules: ['Shopping', 'Research', 'Appointments'],
          stats: {
            total_items: 5,
            completed_items: 2,
            completion_percentage: 40,
            sampling_items: 2,
            training_items: 1,
            building_items: 3
          },
          items_by_module: {
            'Shopping': [
              {
                uuid: 'item-uuid-1',
                name: 'Buy crib',
                description: 'Find a safe and comfortable crib',
                status: 'completed',
                priority: 'must_have',
                trial_suggested: true,
                shared_by: null
              },
              {
                uuid: 'item-uuid-2',
                name: 'Buy car seat',
                description: 'Research and purchase appropriate car seat',
                status: 'completed',
                priority: 'must_have',
                training_required: true,
                shared_by: null
              }
            ],
            'Research': [
              {
                uuid: 'item-uuid-3',
                name: 'Research pediatricians',
                description: 'Find a pediatrician for the baby',
                status: 'in_progress',
                priority: 'must_have',
                shared_by: {
                  name: 'Emma Wilson',
                  uuid: 'user-1'
                }
              }
            ],
            'Appointments': [
              {
                uuid: 'item-uuid-4',
                name: 'Schedule hospital tour',
                description: 'Book a maternity ward tour',
                status: 'not_started',
                priority: 'nice_to_have',
                latest_date: '2023-11-15',
                latest_date_reason: 'Should be done before third trimester',
                shared_by: null
              },
              {
                uuid: 'item-uuid-5',
                name: 'Pre-register at hospital',
                description: 'Complete pre-registration paperwork',
                status: 'not_started',
                priority: 'must_have',
                building_required: true,
                latest_date: '2023-12-01',
                latest_date_reason: 'Required before delivery',
                shared_by: null
              }
            ]
          }
        },
        '123e4567-e89b-12d3-a456-426614174001': {
          uuid: '123e4567-e89b-12d3-a456-426614174001',
          name: 'Build Nursery',
          description: 'Create a cozy and functional nursery for baby',
          modules: ['Research', 'Plan', 'Setup'],
          stats: {
            total_items: 3,
            completed_items: 1,
            completion_percentage: 33,
            sampling_items: 1,
            training_items: 0,
            building_items: 2
          },
          items_by_module: {
            'Research': [
              {
                uuid: 'item-uuid-6',
                name: 'Research nursery furniture',
                description: 'Find the best crib, changing table, and dresser options',
                status: 'completed',
                priority: 'must_have',
                shared_by: null
              }
            ],
            'Plan': [
              {
                uuid: 'item-uuid-7',
                name: 'Create nursery layout',
                description: 'Plan the arrangement of furniture in the nursery',
                status: 'not_started',
                priority: 'must_have',
                building_required: true,
                shared_by: null
              }
            ],
            'Setup': [
              {
                uuid: 'item-uuid-8',
                name: 'Assemble and arrange nursery furniture',
                description: 'Put together the crib, dresser, and other items',
                status: 'not_started',
                priority: 'must_have',
                building_required: true,
                shared_by: null
              }
            ]
          }
        }
      };
      
      // Set plan data from mock
      const foundPlan = mockPlans[planId];
      setPlan(foundPlan);
      
      // Find product data in the plan
      if (foundPlan) {
        // Search through all modules to find the product
        let foundProduct = null;
        Object.values(foundPlan.items_by_module).forEach(items => {
          const matchingProduct = items.find(item => item.uuid === productId);
          if (matchingProduct) {
            foundProduct = matchingProduct;
          }
        });
        
        if (foundProduct) {
          setProduct(foundProduct);
        } else {
          // Product not found, redirect back to plan details
          history.push(`/plan/${planId}`);
        }
      } else {
        // Plan not found, redirect to plans page
        history.push('/plan');
      }
    }
  }, [planId, productId, plan, product, history, location.state]);
  
  const handleGoBack = () => {
    history.push(`/plan/${planId}`);
  };
  
  // Handle sharing the product
  const handleShareProduct = () => {
    setShareModalVisible(true);
  };
  
  const handleRequestRecommendations = () => {
    // Create a custom event to show chat drawer with context
    const event = new CustomEvent('showChatDrawer', { 
      detail: { 
        fromPlanDetails: true,
        forProductSuggestion: true,
        requestType: 'brandModelRecommendations',
        actionLabel: 'See Recommendations',
        productName: product ? product.name : 'nursery furniture', 
        babyName: "Emma", // Use baby name from context
        activateProductExpert: true,
        // Auto-message details
        autoMessage: true,
        messageText: `Can you recommend some good ${product ? product.name : 'nursery furniture'} brands and models for my nursery?`,
        messageAnimationDuration: 250, // Bold text for 0.25 seconds
        sendAfterAnimation: true, // Auto-send after animation
        returnFunction: () => {
          // This should add mock recommendations to the product and update state
          const updatedProduct = { ...product };
          
          // Add mock recommendations if they don't exist
          if (!updatedProduct.recommendations || updatedProduct.recommendations.length === 0) {
            updatedProduct.recommendations = [
              {
                id: 'rec-1',
                name: 'Babyletto Hudson 3-in-1 Convertible Crib',
                price: '$399.99',
                imageUrl: 'https://m.media-amazon.com/images/I/71KbZBlQ2aL._SL1500_.jpg',
                features: [
                  'Space-saving design for smaller rooms',
                  'Converts from crib to toddler bed to daybed',
                  'Made from sustainable New Zealand pine',
                  'GREENGUARD Gold Certified for low chemical emissions',
                  'Mid-century modern design works with most decor styles'
                ]
              },
              {
                id: 'rec-2',
                name: 'IKEA Sniglar Crib',
                price: '$149.99',
                imageUrl: 'https://www.ikea.com/us/en/images/products/sniglar-crib-beech__0419592_pe576389_s5.jpg',
                features: [
                  'Budget-friendly option',
                  'Solid beech wood construction',
                  'Minimalist Scandinavian design',
                  'Compact size perfect for smaller spaces',
                  'Simple assembly'
                ]
              }
            ];
          }
          
          // Update product and show recommendations
          setProduct(updatedProduct);
          setShowEmptyRecommendationsState(false);
        }
      }
    });
    
    // Dispatch the event to be caught by MainNavigation component
    window.dispatchEvent(event);
  };

  // Handle deleting a product
  const handleDeleteProduct = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this product?',
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        // In a real app, you would make an API call to delete the product
        // For this demo, we'll just navigate back to the plan details
        message.success(`Product "${product.name}" deleted successfully.`);
        history.push(`/plan/${planId}`);
      }
    });
  };

  // Don't render anything if we're still loading or data not found
  if (!plan || !product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="plan-details-container">
      <div className="plan-details-header">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
          <Breadcrumb style={{ fontSize: '14px' }}>
            <Breadcrumb.Item href="/plan">
              <HomeOutlined /> Plans
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/plan/${planId}`}>
              <span>{plan.name}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{product.name}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          
          <Button icon={<ArrowLeftOutlined />} onClick={handleGoBack}>
            Back to Plan
          </Button>
        </div>
      </div>
      
      <Card className="plan-details-card">
        <div className="item-details-content">
          {/* Product Header */}
          <div className="product-header" style={{ marginBottom: '24px' }}>
            <Title level={2}>{product.name}</Title>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: '8px' }}>
              <Checkbox 
                checked={product.status === 'completed'} 
                onChange={(e) => {
                  // In a real app, this would update the product status
                  const updatedProduct = { ...product };
                  updatedProduct.status = e.target.checked ? 'completed' : 'not_started';
                  setProduct(updatedProduct);
                }}
              >
                Mark as Completed
              </Checkbox>
              
              <Button 
                type="text" 
                icon={<ShareAltOutlined />}
                onClick={handleShareProduct}
                style={{ marginRight: '8px' }}
              >
                Share
              </Button>
              
              <Button 
                type="text" 
                danger
                icon={<DeleteOutlined />}
                onClick={handleDeleteProduct}
              >
                Delete
              </Button>
            </div>
          </div>
          
          {product.description && (
            <div className="item-detail-section">
              <Title level={5}>Description</Title>
              <Paragraph>{product.description}</Paragraph>
            </div>
          )}
          
          <div className="item-detail-section">
            <Title level={5}>Details</Title>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Text strong>Status: </Text>
                {product.status === 'completed' && <Tag color="success">Completed</Tag>}
                {product.status === 'in_progress' && <Tag color="processing">In Progress</Tag>}
                {product.status === 'not_started' && <Tag color="default">Not Started</Tag>}
              </Col>
              <Col span={12}>
                <Text strong>Priority: </Text>
                {product.priority === 'must_have' && <Tag color="red">Must Have</Tag>}
                {product.priority === 'nice_to_have' && <Tag color="orange">Nice to Have</Tag>}
                {product.priority === 'not_worth_it' && <Tag color="default">Not Worth It</Tag>}
              </Col>
              <Col span={12}>
                <Text strong>Category: </Text>
                <Tag>{product.module}</Tag>
              </Col>
              {product.shared_by && (
                <Col span={12}>
                  <Text strong>Shared By: </Text>
                  <Tag color="blue">
                    <UserOutlined /> {product.shared_by.name}
                  </Tag>
                </Col>
              )}
            </Row>
          </div>
          
          {(product.trial_suggested || product.training_required || product.building_required) && (
            <div className="item-detail-section">
              <Title level={5}>Requirements</Title>
              <Row gutter={[16, 16]}>
                {product.trial_suggested && (
                  <Col span={24}>
                    <div className="requirement-item">
                      <Tag color="blue">Try Before Buy</Tag>
                      <Text>You might want to sample or try this item before purchasing</Text>
                    </div>
                  </Col>
                )}
                {product.training_required && (
                  <Col span={24}>
                    <div className="requirement-item">
                      <Tag color="purple">Training Required</Tag>
                      <Text>You'll need to learn how to use this item properly</Text>
                    </div>
                  </Col>
                )}
                {product.building_required && (
                  <Col span={24}>
                    <div className="requirement-item">
                      <Tag color="orange">Assembly Required</Tag>
                      <Text>This item requires assembly or installation</Text>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          )}
          
          {product.latest_date && (
            <div className="item-detail-section">
              <Title level={5}>Timeline</Title>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="requirement-item">
                    <Tag color="magenta">By {product.latest_date}</Tag>
                    <Text>{product.latest_date_reason || 'This item should be acquired by this date'}</Text>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          
          {/* Suggested Products Section - Show for all products */}
          <div className="item-detail-section" id="recommendations-section">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <Title level={5} style={{ margin: 0 }}>Suggested Products</Title>
              {/* Demo button to toggle between empty state and recommendations */}
              <Button 
                type="link" 
                size="small"
                onClick={() => setShowEmptyRecommendationsState(!showEmptyRecommendationsState)}
              >
                {showEmptyRecommendationsState ? "Show Recommendations (Demo)" : "Show Empty State"}
              </Button>
            </div>
            
            {!showEmptyRecommendationsState && product && product.recommendations && product.recommendations.length > 0 ? (
              // Show recommendations if available
              <div style={{ marginTop: '15px' }}>
                <List
                  itemLayout="horizontal"
                  dataSource={product.recommendations}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <Card 
                        style={{ width: '100%', marginBottom: '10px' }}
                        hoverable
                      >
                        <div style={{ display: 'flex', gap: '15px' }}>
                          <div style={{ width: '120px', flexShrink: 0 }}>
                            <img 
                              src={item.imageUrl} 
                              alt={item.name} 
                              style={{ width: '100%', height: 'auto', borderRadius: '4px' }} 
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <Title level={5} style={{ margin: '0 0 5px 0' }}>{item.name}</Title>
                            <Text type="secondary">{item.price}</Text>
                            <ul style={{ paddingLeft: '20px', marginTop: '8px' }}>
                              {item.features.slice(0, 3).map((feature, idx) => (
                                <li key={idx} style={{ fontSize: '12px', marginBottom: '3px' }}>
                                  {feature}
                                </li>
                              ))}
                            </ul>
                            <div style={{ marginTop: '10px' }}>
                              <Button type="primary" size="small">
                                Add to Cart
                              </Button>
                              <Button 
                                size="small" 
                                style={{ marginLeft: '8px' }}
                                onClick={() => {
                                  // Navigate to brand model details page
                                  const brandModelId = item.id.replace('rec-', 'bm-');
                                  history.push({
                                    pathname: `/plan/${planId}/product/${productId}/brand/${brandModelId}`,
                                    state: {
                                      planData: plan,
                                      productData: product
                                    }
                                  });
                                }}
                              >
                                View Details
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </List.Item>
                  )}
                />
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                  <Text type="secondary">Recommended by Bibi based on your needs</Text>
                </div>
              </div>
            ) : (
              <>
                {/* Show empty state with button to get recommendations */}
                <div className="empty-plans" style={{ textAlign: 'center', padding: '24px', marginTop: '12px' }}>
                  <Title level={4} className="empty-plans-title">No suggested brands/models</Title>
                  <Paragraph className="empty-plans-description">
                    We're working on personalized brand and model recommendations for this product
                  </Paragraph>
                  <Button 
                    type="primary" 
                    icon={<PlusOutlined />}
                    onClick={handleRequestRecommendations}
                    style={{ 
                      marginTop: '16px',
                      background: '#5c9dff',
                      borderColor: '#4b87e8',
                      borderRadius: '18px',
                      height: '36px',
                      boxShadow: '0 3px 0 rgba(76, 111, 255, 0.1)'
                    }}
                  >
                    Get Nursery Product Suggestions
                  </Button>
                </div>
                
                {/* Responsive product cards grid */}
                <div style={{ marginTop: '30px' }}>
                  <Divider>
                    <Text type="secondary">All {product ? product.name : 'Products'}</Text>
                  </Divider>
                  
                  <Row gutter={[16, 16]} style={{ marginTop: '12px' }}>
                    {/* Sample product cards */}
                    {[
                      {
                        id: 'pop-1',
                        name: product && product.name.toLowerCase().includes('crib') ? 'DaVinci Kalani 4-in-1 Crib' : 'Ergobaby Carrier',
                        price: product && product.name.toLowerCase().includes('crib') ? '$179.99' : '$120.00',
                        imageUrl: product && product.name.toLowerCase().includes('crib') 
                          ? 'https://m.media-amazon.com/images/I/71K5NJ-Ua3L._SL1500_.jpg'
                          : 'https://m.media-amazon.com/images/I/71Vr9GQ2s9L._SL1500_.jpg',
                        rating: 4.7,
                        reviews: 2340,
                        registries: ['Babylist', 'Amazon Baby']
                      },
                      {
                        id: 'pop-2',
                        name: product && product.name.toLowerCase().includes('crib') ? 'Graco Benton 4-in-1 Crib' : 'Britax B-Safe Infant Car Seat',
                        price: product && product.name.toLowerCase().includes('crib') ? '$149.99' : '$199.99',
                        imageUrl: product && product.name.toLowerCase().includes('crib')
                          ? 'https://m.media-amazon.com/images/I/71vHxTZBfoL._SL1500_.jpg'
                          : 'https://m.media-amazon.com/images/I/71BWsz2prOL._SL1500_.jpg',
                        rating: 4.5,
                        reviews: 1820,
                        registries: ['Target Baby', 'BuyBuy Baby', 'Amazon Baby']
                      },
                      {
                        id: 'pop-3',
                        name: product && product.name.toLowerCase().includes('crib') ? 'IKEA Gulliver Crib' : 'UPPAbaby VISTA Stroller',
                        price: product && product.name.toLowerCase().includes('crib') ? '$119.99' : '$899.99',
                        imageUrl: product && product.name.toLowerCase().includes('crib')
                          ? 'https://www.ikea.com/us/en/images/products/gulliver-crib-white__0637931_pe698633_s5.jpg'
                          : 'https://m.media-amazon.com/images/I/71qy3xgBQLL._SL1500_.jpg',
                        rating: 4.3,
                        reviews: 980,
                        registries: ['Babylist', 'IKEA Registry']
                      }
                    ].map(product => (
                      <Col xs={24} sm={12} md={8} key={product.id}>
                        <Card
                          hoverable
                          cover={
                            <div style={{ height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '12px', background: '#f9f9f9' }}>
                              <img
                                alt={product.name}
                                src={product.imageUrl}
                                style={{ maxHeight: '160px', maxWidth: '100%', objectFit: 'contain' }}
                              />
                            </div>
                          }
                          bodyStyle={{ padding: '12px' }}
                        >
                          <div style={{ marginBottom: '8px', height: '40px', overflow: 'hidden' }}>
                            <Text strong style={{ fontSize: '14px' }}>{product.name}</Text>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text type="secondary" strong style={{ fontSize: '16px', color: '#5c9dff' }}>{product.price}</Text>
                            <div>
                              <Rate disabled defaultValue={product.rating} style={{ fontSize: '12px' }} />
                              <Text type="secondary" style={{ fontSize: '12px', marginLeft: '4px' }}>({product.reviews})</Text>
                            </div>
                          </div>
                          
                          {/* Registry tags */}
                          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginTop: '10px', marginBottom: '10px' }}>
                            {product.registries.map((registry, idx) => (
                              <Tag 
                                key={idx} 
                                style={{ 
                                  borderRadius: '12px', 
                                  backgroundColor: '#f0f5ff', 
                                  color: '#5c9dff',
                                  border: '1px solid #d9e6ff',
                                  fontSize: '11px',
                                  padding: '0 8px'
                                }}
                              >
                                {registry}
                              </Tag>
                            ))}
                          </div>
                          
                          <Button 
                            type="primary" 
                            block 
                            style={{ marginTop: '4px', background: '#5c9dff', borderColor: '#4b87e8' }}
                            onClick={() => {
                              // Navigate to brand model details page with a brandModelId based on product index
                              const brandModelId = product.id === 'pop-1' ? 'bm-123' : 'bm-456';
                              history.push({
                                pathname: `/plan/${planId}/product/${productId}/brand/${brandModelId}`,
                                state: {
                                  planData: plan,
                                  productData: product
                                }
                              });
                            }}
                          >
                            View Details
                          </Button>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </>
            )}
          </div>
          
          <div className="item-detail-actions" style={{ marginTop: '24px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              type="primary" 
              danger
              icon={<DeleteOutlined />}
              onClick={handleDeleteProduct}
            >
              Delete Product
            </Button>
          </div>
        </div>
      </Card>
      
      {/* Share Modal */}
      {product && (
        <ShareModal
          visible={shareModalVisible}
          onCancel={() => setShareModalVisible(false)}
          itemType="product"
          itemName={product.name}
          itemId={product.uuid}
        />
      )}
      
      {/* Add space at the bottom to prevent content being hidden by the navigation menu */}
      <div style={{ height: '120px' }}></div>
    </div>
  );
};

export default ProductDetails;