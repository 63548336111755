import React, { useState } from 'react';
import { Form, DatePicker, Input, Typography, Space } from 'antd';

const { Title, Text } = Typography;

const PregnancyQuestions = ({ onNext, onPrevious, updateUserData }) => {
  const [form] = Form.useForm();
  const [dueDate, setDueDate] = useState(null);

  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
      dueDate: values.dueDate ? values.dueDate.format('YYYY-MM-DD') : null,
    };
    
    updateUserData(formattedValues);
    onNext();
  };

  return (
    <div className="pregnancy-questions">
      <Title level={3}>Tell us about your pregnancy</Title>
      <Text>This information helps us personalize your experience</Text>
      
      <Form 
        form={form} 
        layout="vertical"
        onFinish={handleSubmit}
        style={{ marginTop: 24 }}
      >
        <Form.Item
          name="dueDate"
          label="When is your due date?"
          rules={[{ required: true, message: 'Please enter your due date' }]}
        >
          <DatePicker 
            style={{ width: '100%' }} 
            placeholder="Select due date"
            onChange={(date) => setDueDate(date)}
          />
        </Form.Item>
        
        <Form.Item
          name="childName"
          label="Do you have a name picked out? (optional)"
        >
          <Input placeholder="Baby's name" />
        </Form.Item>
        
        <Space style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24 }}>
          <button 
            type="button" 
            onClick={onPrevious}
            className="prev-button"
          >
            Back
          </button>
          <button 
            type="submit"
            className="next-button"
          >
            Continue
          </button>
        </Space>
      </Form>
    </div>
  );
};

export default PregnancyQuestions;