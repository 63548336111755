import React from 'react';
import { Form, Input, Button, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const InviteForm = ({ form, onFinish }) => (
  <Form form={form} onFinish={onFinish} layout="vertical" className="invite-form">
    <Title level={2}>Invite Your Support Network</Title>

    <Form.Item name="partnerEmail" label="Invite Your Partner">
      <Paragraph>Share this journey with your partner. They'll have access to the information you choose to share.</Paragraph>
      <Input placeholder="Partner's email" />
    </Form.Item>

    <Form.Item name="friendEmails" label="Invite Friends & Family">
      <Paragraph>Let your support network join you on this exciting journey.</Paragraph>
      <TextArea
        rows={4}
        placeholder="Enter email addresses (one per line)"
      />
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit">
        Send Invites
      </Button>
    </Form.Item>
  </Form>
);

export default InviteForm;
