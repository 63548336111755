import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message } from 'antd';
import PrivacyStatement from './PrivacyStatement';
import UserTypeSelection from './UserTypeSelection';
import PregnancyQuestions from './PregnancyQuestions';
import InviteForm from './InviteForm';
import * as actions from '../../redux/users/actions';

const Onboarding = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const userState = useSelector(state => state.users);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleUserTypeSelect = (type) => {
    dispatch(actions.setUserType(type));
    nextStep();
  };

  const handlePregnancyQuestionsUpdate = (field, value) => {
    switch (field) {
      case 'pregnancyStatus':
        dispatch(actions.setPregnancyStatus(value));
        break;
      case 'dueDate':
        dispatch(actions.setDueDate(value));
        break;
      case 'babyName':
        dispatch(actions.setBabyName(value));
        break;
      case 'pregnancyPhoto':
        dispatch(actions.setPregnancyPhoto(value));
        break;
      case 'privacyLevel':
        dispatch(actions.setPrivacyLevel(value));
        break;
      case 'childrenCount':
        dispatch(actions.setChildrenCount(value));
        break;
      default:
        break;
    }
  };

  const handleInviteUpdate = (field, value) => {
    switch (field) {
      case 'partnerEmail':
        dispatch(actions.setPartnerEmail(value));
        break;
      case 'inviteFriends':
        dispatch(actions.setInviteFriends(value));
        break;
      default:
        break;
    }
  };

  const handleSendInvites = async () => {
    try {
      const invites = {
        partnerEmail: userState.partnerEmail,
        friendEmails: userState.inviteFriends,
      };
      await dispatch(actions.sendInvitesAction(invites));
      message.success('Invites sent successfully!');
      nextStep();
    } catch (error) {
      message.error('Failed to send invites. Please try again.');
    }
  };

  const handleComplete = async () => {
    try {
      await dispatch(actions.completeOnboardingAction(userState));
      message.success('Onboarding completed successfully!');
      // Redirect to main app or show success message
    } catch (error) {
      message.error('Failed to complete onboarding. Please try again.');
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <PrivacyStatement onContinue={nextStep} />;
      case 1:
        return <UserTypeSelection selectedType={userState.userType} onSelect={handleUserTypeSelect} />;
      case 2:
        return (
          <PregnancyQuestions
            pregnancyStatus={userState.pregnancyStatus}
            dueDate={userState.dueDate}
            babyName={userState.babyName}
            pregnancyPhoto={userState.pregnancyPhoto}
            privacyLevel={userState.privacyLevel}
            childrenCount={userState.childrenCount}
            onUpdatePregnancyStatus={(value) => handlePregnancyQuestionsUpdate('pregnancyStatus', value)}
            onUpdateDueDate={(value) => handlePregnancyQuestionsUpdate('dueDate', value)}
            onUpdateBabyName={(value) => handlePregnancyQuestionsUpdate('babyName', value)}
            onUpdatePregnancyPhoto={(value) => handlePregnancyQuestionsUpdate('pregnancyPhoto', value)}
            onUpdatePrivacyLevel={(value) => handlePregnancyQuestionsUpdate('privacyLevel', value)}
            onUpdateChildrenCount={(value) => handlePregnancyQuestionsUpdate('childrenCount', value)}
          />
        );
      case 3:
        return (
          <InviteForm
            partnerEmail={userState.partnerEmail}
            friendEmails={userState.inviteFriends}
            onUpdatePartnerEmail={(value) => handleInviteUpdate('partnerEmail', value)}
            onUpdateFriendEmails={(value) => handleInviteUpdate('inviteFriends', value)}
            onSendInvites={handleSendInvites}
          />
        );
      case 4:
        return (
          <div>
            <h2>You're All Set!</h2>
            <p>Welcome to MeSearch. Your privacy settings are in place, and we're ready to support you on your journey.</p>
            <Button type="primary" onClick={handleComplete}>Enter MeSearch</Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {renderStep()}
      {step > 0 && step < 4 && (
        <Button onClick={prevStep}>Back</Button>
      )}
      {step < 4 && step !== 1 && (
        <Button type="primary" onClick={nextStep}>Next</Button>
      )}
    </div>
  );
};

export default Onboarding;
